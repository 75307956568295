import { AppBar, Avatar, Box, Button, Chip, Container, Divider, Hidden, Link, MenuItem, Popover, styled, Toolbar, Typography } from '@mui/material';

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Logo from '../assets/images/logo.png'
import { connect } from 'react-redux';
import { authRequest, logoutUser } from '../actions/actions';


const StyledContainer = styled(Container)(({theme}) => ({
    '& .MuiContainer-root': {
        background: theme.secondaryColorDark
    },
    '& .MuiBadge-anchorOriginTopRightCircular' : {
        top : '23%',
        right: '23%',
        color: '#000'
    },
    '& .MuiBadge-colorSecondary':{
        background: theme.primaryColorOrange
    },
    '& .MuiChip-outlined' : {
        border: 'none',
        marginLeft: '.5rem'
    },
    '& .MuiChip-label' : {
        color: '#fff'
    },
    '& .MuiPopover-paper' : {
        borderRadius: '1rem'
    }
}))

const StyledMenu = styled(Box)(({theme}) => ({
    width: '9rem',
    padding: '.8rem',
    '& li': {
        borderRadius: '.3rem'
    },
    '& h6': {
        fontSize: '1rem'
    }
}))



const Navbar = (props) => {
    const { authRequest } = props
    const  path = useLocation()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

  
    // Get User Auth & Fetch All Products into store
    useEffect(() => {
        authRequest()
    }, [authRequest])

    const handleClose = () => {
        setAnchorEl(null);
    }
    const onhandleClick = (e) => {
        setAnchorEl(e.currentTarget);
    }
    const toDashboard = (link) => {
        if(props.currentUser.verificationCode){
            window.location.assign('/auth/verify')
        }else{
                window.location.assign(`/u/account/${link}`)        
        }
    }

  return (
      <Box style={{background: props.bg}} >
        <AppBar elevation={0} position='static' sx={{bgcolor: 'transparent'}}>
            <Toolbar sx={{
                 height: '96px',
            }}>
                <StyledContainer maxWidth='xl'>
                    <Box sx={{
                        display: 'flex',
                        gap: theme => theme.spacing(3),
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        '& a' : {
                            marginLeft: theme => theme.spacing(4)
                        }
                    }}>
                        
                        { path.pathname.startsWith('/auth/') ? null 
                        : 
                        <Hidden smDown>
                            <Typography sx={{
                                color: '#fff',
                                '& a:hover': {
                                    color: theme => theme.primaryColorOrange
                                }
                            }}> 
                                <Link color='inherit' underline='none' href='/'>Home</Link>
                                {/* <Link color='inherit' underline='none' href='/shop'>Partner Store</Link> */}
                                <Link color='inherit' underline='none' href='/contact'>Contact</Link>
                            </Typography>
                        </Hidden>
                        }

                        <div>
                        <Link href='/' style={{marginLeft: 0}}>
                            <img style={{ width:'120px' }} src={Logo} alt="logo" />
                        </Link>
                        </div>

                        <div sx={{
                            '& .MuiSvgIcon-root' : {
                                color: '#fff'
                            }
                        }}>
                            { path.pathname.startsWith('/auth/') ?  
                            <Typography color='textSecondary'> <Link href='/contact' color='inherit' underline='none'> Support </Link></Typography> 
                            :
                            <>
                                
                                { props.currentUser ? 
                                    <>
                                        <Chip avatar={<Avatar src={props.currentUser.photo ? props.currentUser.photo : null} />} variant='outlined' label={<Typography>My Account</Typography>} onClick={(e)=> onhandleClick(e)}  /> 
                                            <Popover open={open} onClose={handleClose} anchorEl={anchorEl} transformOrigin={{vertical: 'top', horizontal: 'right'}} anchorOrigin={{ vertical: 'bottom', horizontal: 'right'}}>
                                                <StyledMenu >
                                                    <Typography variant='h6'>{`${props.currentUser.name.firstname}`}</Typography>
                                                    <Typography gutterBottom variant='body2' color='textSecondary' noWrap>{props.currentUser.email}</Typography>
                                                </StyledMenu>
                                                <Divider />
                                                <StyledMenu>
                                                    <MenuItem dense onClick={()=> toDashboard('dashboard')}>Dashboard</MenuItem>
                                                    <MenuItem dense onClick={()=> toDashboard('settings')}>Settings</MenuItem>
                                                    <MenuItem dense onClick={()=> window.location.assign('https://oakexpressgh.com')}>Partner Store</MenuItem>
                                                </StyledMenu>
                                                <Divider />
                                                <StyledMenu>                          
                                                    <MenuItem dense onClick={()=> props.logoutUser()} >Logout</MenuItem>
                                                </StyledMenu>

                                            </Popover>
                                    </>
                                    : 
                                        <Button href='/auth/login' sx={{
                                            color: '#fff',
                                            margin: '0 8px !important'
                                        }} disableElevation variant='text'>Login</Button>
                                }
                                { props.currentUser ? null :  
                                <Hidden smDown>
                                    <Button href='/auth/register' 
                                    sx={{
                                        color: '#fff',
                                        borderColor: '#fff',
                                        marginLeft: '5px !important',
                                    }} disableElevation variant='outlined'>Register</Button>
                                </Hidden>  }
                                
                            </>
                            }
                        </div>

                    </Box>
                </StyledContainer>
            </Toolbar>
        </AppBar>
      </Box>
    
  )
};

const mapStateToProps = (state) => {
    //console.log(state)
    return state
}

export default connect(mapStateToProps, {authRequest, logoutUser})(Navbar);
