import { Avatar, Box, Breadcrumbs, Button, Checkbox,Chip, Dialog, DialogActions, DialogContent, Divider, FormControlLabel,  IconButton, InputAdornment, Link, MenuItem, TextField, Typography } from '@mui/material';
import { Close, CheckCircle, Verified, Payment } from '@mui/icons-material';
import React, { useReducer, useState } from 'react';
import { connect } from 'react-redux';
import { requestLoan, errorModal, popupSettings, welcomePopup, submitPopup, uploadPayslip, successModal, topupLoan } from '../../../actions/actions';
import Modal from '../../../components/Modal';
import Award from '../../../assets/images/award.gif'
import { makeStyles } from '@mui/styles';
import { grey } from '@mui/material/colors';
// import UploadFile from '../../../assets/images/file-upload.png';
import 'animate.css'
import { LoadingButton } from '@mui/lab';


const useStyles = makeStyles(theme => ({
  crumbs: {
      fontSize: '13px'
  },
  page: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '50px'
  },
  active: {
    color: theme.backgroundPrimary
  },
  field : {
    marginTop: '1rem'
  },
  justify: {
    textAlign: 'justify'
}
}))



const PageHeader = ({tourGuide, welcomePopup, submitPopup, popupSettings, errorModal, successModal, modal, title, link1, link2, currentUser, interest, requestLoan, uploadPayslip, topupLoan}) => {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const [load2, setLoad2] = useState(false)
    // const [doc, setDoc] = useState()
    // const [slip, setSlip] = useState()
    // const [sdo, setSDO] = useState()
    // const [statement, setStatement] = useState()
    const [accept, setAccept] = useState(false)
    const [pay, setPay ] = useState(0)
    const [load, setLoad] = useState(true)
    // const ref = useRef()
    // const ref2 = useRef()


    const calcPayment = (loan, rate, dur) => {
        //Principal + (principal x interest x Rate) ÷ Duration
        if(loan === '' || rate === 0 || dur === ''){
          return
        }
        const interest = ( loan * ((rate * dur) / 100) )
        const total = interest + loan;
        const result = (total / dur)
        setPay(result.toFixed(2))
    }

    const initialState = {
      amount: '',
      duration: '',
    }

    const reducerFn = (state, action) => {
      switch (action.type) {
        case "LOAN":
          calcPayment(action.payload * 1, interest.rate, state.duration)
          return {...state, amount: action.payload.toLocaleString()}
        case "DURATION":
          calcPayment(state.amount * 1, interest.rate, action.payload)
          return {...state, duration: action.payload}
        case "RESET":
          return initialState
        default:
          return state;
      }
    }

    const [formInput, dispatch] = useReducer(reducerFn, initialState)

    const onHandleClose = () => {
      dispatch({type: "RESET"})
      // setDoc()
      // setSDO()
      setAccept(false)
      setPay(0)
      setOpen(false)
    }

    const onPopUpClose = () => {
      popupSettings();
      window.location.assign('/u/account/payment');
    }

    const closeWelcomeScreen = () => {
      setLoad(false)
      welcomePopup()
      setTimeout(()=>{
        tourGuide()
      },1000)
    }

    const closeSubmitScreen = () => {
      setLoad(false)
      submitPopup()
    }

    const getLoan = () => {
      if(currentUser?.completion < 70){
        errorModal('Please complete your Account Status to be eligible for a Loan. Thank you')
        return
      }
      if(currentUser?.status === 'Pending'){
        errorModal('Sorry, your account must be verified to be eligible for a Loan.')
        return
      }
      if((currentUser?.loan[0]?.limit - currentUser?.loan[0]?.total) === 0){
        errorModal(`Sorry, you have exhausted your credit balance. Please pay off to request another loan`)
        return
      }
      // if(currentUser?.documents?.sdo !== ""){
      //   setDoc(currentUser?.documents.sdo)
      // }
      // if(currentUser?.documents?.payslip !== ""){
      //   setSlip(currentUser?.documents.payslip)
      // }
      setOpen(true)
    }

    //read document when uploaded
  //   const readDocumentFile = (file) => {
  //       const reader = new FileReader();
  //       reader.addEventListener('load', (event) => {
  //         const result = event.target.result;
  //       // console.log(result)
  //         setDoc(result)
  //       });
  //       reader.readAsDataURL(file);
  //   }

  //   const readPaySlipFile = (file) => {
  //     const reader = new FileReader();
  //     reader.addEventListener('load', (event) => {
  //       const result = event.target.result;
  //     // console.log(result)
  //       setSlip(result)
  //     });
  //     reader.readAsDataURL(file);
  // }


    //loan business docs
    // const selectDocument = async (e) => {
    //   // console.log(e.target.files[0].size)
    //   if(e.target.files[0].size >= 1000000){
    //       errorModal(`Image is too large: Max size is 1MB`)
    //       return;
    //   }
    //   //readfiles
    //   readDocumentFile(e.target.files[0])
    //   setSDO(e.target.files[0])
    // } 

    // const selectPayslip = async (e) => {
    //   // console.log(e.target.files[0])
    //   if(e.target.files[0].size >= 1000000){
    //       errorModal(`Image is too large: Max size is 1MB`)
    //       return;
    //   }
    //   //readfiles
    //   readPaySlipFile(e.target.files[0])
    //   setStatement(e.target.files[0])
    // }

    const onLoanRequest = async() => {
      if(formInput.amount === '' || formInput.duration === '' ){
        errorModal('Invalid. Provide loan amount and duration')
        return 
      }
      // if(!sdo && currentUser?.documents?.sdo === "" ){
      //   errorModal('Invalid. Provide a Standing Order')
      //   return 
      // }
      // if(!statement && currentUser?.documents?.payslip === ""){
      //   errorModal('Invalid. Provide your Bank Statement or Payslip')
      //   return 
      // }

      setLoad2(true)
      // create formData
      const formData = new FormData();
      formData.append('amount', formInput.amount * 1 )
      formData.append('duration', formInput.duration)
      formData.append('interest', interest.rate)
      formData.append('perMonth', pay)
      // formData.append('sdo', sdo)

      //
      const topup = {
        amount : formInput.amount * 1,
        duration:  formInput.duration,
        interest: interest.rate,
        perMonth: pay
      }

      // const data = new FormData();
      // data.append('payslip', statement)

      successModal('Submitting loan request. Please wait')
      // if(statement){
      //   await uploadPayslip(data)
      // }
      if(currentUser?.documents?.sdo === ""){
        await requestLoan(formData)
      }else{
        await topupLoan(topup)
        dispatch({type: "RESET"})
        setLoad2(false)
        setAccept(false)
        setPay(0)
        setOpen(false)
      }
    }

    const checkLimit = (e) => {
      if(e.target.value > (currentUser?.loan[0]?.limit - currentUser?.loan[0]?.total)){
        errorModal('Invalid. Loan amount is beyond your available credit balance')
        return
      }
      dispatch({type: "LOAN", payload: e.target.value})
    }

  return (
    <>
      {/* Verification Bar */}
      {
        currentUser?.bioStatus > 0 && currentUser?.emailStatus > 0 && currentUser?.bankStatus > 0 && currentUser?.status === 'Pending' ?
        <Box sx={{padding: '.7rem 1rem',mb: '2rem', bgcolor: grey[500], display: 'flex', gap: '1rem', borderRadius: '10px', alignItems: 'center', color: '#fff'}}>
          <Avatar sx={{width: '2.2rem', height: '2.2rem', bgcolor: '#fff'}}><Verified color='primary' sx={{fontSize: '1.5rem'}} /></Avatar>
          <Typography sx={{fontSize: '.9rem'}}>Your account is under verfication. You will be prompted when completed</Typography>
          <IconButton sx={{marginLeft: 'auto'}}><Close fontSize='small' /></IconButton>
        </Box>
        : null
      }

      <div className={classes.page}>
      { modal && <Modal status={modal.status} /> }
      <Box>
        <Typography variant='h5' gutterBottom> {title} </Typography>
        <Breadcrumbs>
          <Link className={classes.crumbs} color='inherit' href='/u/account/dashboard'> Overview </Link>
          { link1 ? <Link className={classes.crumbs} color='inherit' href={`/u/account/${link1.toLowerCase()}`} > {link1} </Link> : null }
          <Typography className={`${classes.crumbs} ${classes.active}`} color="textPrimary" > {link2} </Typography>
        </Breadcrumbs> 

      </Box>

        <Button size='large' id='getLoan' variant='contained' color='secondary' sx={{height: '2.5rem', borderRadius: '50px'}} disableElevation onClick={getLoan} >Get a Loan</Button>


      

      {/* POPUP */}
      <Dialog open={ currentUser?.isFirstTime === true && load } maxWidth={'sm'} >
        <DialogContent style={{position: 'relative'}} >
        <Box textAlign={'center'}>
            <img src={Award} width='40%' alt='award' />
        </Box>
        <Box textAlign={'center'} marginBottom='2rem'>
          <Typography variant='h6' sx={{fontWeight: 600, fontSize: {xs: '1.2rem', sm:'1.2rem',lg:'1.6rem'} }}>Welcome to WePayGh</Typography>
          <Typography color='textSecondary' paragraph variant='body1' sx={{padding: '0 1rem'}}>Congratulations!! Your account registration is successful. <br />Please complete the following stages to qualify for a LOAN. </Typography>
          <Box sx={{display: {xs: 'block',sm: 'block', lg: 'flex'} }}  gap={'1rem'} justifyContent='center' marginBottom={'2rem'}>
            <Chip sx={{mb: 1}} icon={<CheckCircle color='primary' fontSize='small' />} label='Activate Email' />
            <Chip sx={{mb: 1}} icon={<CheckCircle color='primary' fontSize='small' />} label='Submit Bank Details' />
            <Chip sx={{mb: 1}} icon={<CheckCircle color='primary' fontSize='small' />} label='Upload Documents' />
          </Box>
          <Button size='large' variant='contained' disableElevation color='secondary' onClick={closeWelcomeScreen}>Get Started</Button>
        </Box>
        </DialogContent>
      </Dialog>

      {/* SUBMITTED DOCUMENTS */}
      <Dialog open={ currentUser?.isSubmitted === true && load } maxWidth='sm'  >
        <DialogContent sx={{position: 'relative', padding: '1rem'}} >
          <CheckCircle sx={{fontSize: '4rem', margin: '0 auto', display: 'block'}} color='primary' />
        <Box textAlign={'center'} marginBottom='2rem'>
          <Typography mt={1} variant='h6' style={{fontWeight: 600, fontSize: '1.6rem'}}>Thank You</Typography>
          <Typography color='textSecondary' paragraph variant='body1' style={{padding: '0 1rem'}}>Your bank details have been submitted successfully. Your account is currently under verification. We will prompt you when this process is done.</Typography>
          <Button size='large' variant='contained' disableElevation color='secondary' onClick={closeSubmitScreen}>Close</Button>
        </Box>
        </DialogContent>
      </Dialog>


      {/* GET A LOAN */}
      <Dialog open={open} maxWidth={'sm'}>
        <Box padding={' 1rem 2rem'}>
          <Typography variant='h6' style={{fontWeight: 600, fontSize: '1.5rem'}}>Loan Request</Typography>
          <Typography color='textSecondary' variant='body2'> Fill out the form below and upload standing order to request for a loan</Typography>
        </Box>
        <Divider />
          <DialogContent sx={{ padding: ' 1rem 2rem' }}>
            <Box sx={{display: 'flex', alignItems: 'center', gap: '.7rem', padding: '10px', bgcolor: 'primary.main', borderRadius: '8px'}}>
              <Avatar sx={{width: '1.8rem', height: '1.8rem', bgcolor: 'secondary.main'}}><Payment fontSize='small' /></Avatar>
              <Typography>Your Credit Limit is <b>GH¢{currentUser?.loan ? currentUser?.loan[0]?.limit?.toLocaleString() : '0.00'}</b></Typography>
            </Box>
            <TextField type='number' required value={ formInput.amount } sx={{marginTop: '1rem'}} fullWidth variant='outlined' inputProps={{ step: 100 }} InputProps={{
              endAdornment : <InputAdornment position='end'><Button disabled disableElevation 
              sx={{textTransform: 'none', mr: '-.8rem'}}> Bal(GH¢{ currentUser?.loan ? currentUser?.loan[0]?.limit - currentUser?.loan[0]?.total : '0.00'})</Button> </InputAdornment>
            }} onChange={(e) => checkLimit(e)} label='Loan Amount' />

            <TextField sx={{marginTop: '1rem'}} required fullWidth select defaultValue={''} value={formInput.duration} 
            onChange={(e)=>dispatch({type: "DURATION", payload: e.target.value})} label='Duration' variant='outlined'>
              {
                [1,2,3,4,5,6,7,8,9,10,11,12 ].map((el) => {
                  return (
                    <MenuItem key={el} value={el}>
                      <Typography>{el} Month(s)</Typography>
                    </MenuItem>
                  )
                })
              }
            </TextField>

            <TextField sx={{marginTop: '1rem'}} variant='outlined' InputProps={{
              endAdornment : <InputAdornment position='end'> <Button disabled disableElevation>Interest</Button> </InputAdornment>
            }}  fullWidth value={interest ? `${interest.rate}%` : 0 } />

            <TextField sx={{marginTop: '1rem'}} variant='outlined' InputProps={{
              startAdornment : <InputAdornment position='start'>GH¢</InputAdornment>,
              endAdornment : <InputAdornment position='end'> <Button disabled disableElevation>Pay per Month</Button> </InputAdornment>
            }}  fullWidth value={pay.toLocaleString()} />

            {/* <Box sx={{display: isMobile ? 'block' : 'block', gap: '1rem'}}>
            {
              currentUser?.sdo ? null :
                <Box mt={2} sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '.7rem', padding: '6px 10px', bgcolor: 'primary.main', borderRadius: '8px'}}>
                  <Typography variant='body2'>Download the Standing Order Form</Typography>
                  <Button size='small' onClick={() => {
                    onHandleClose();
                    window.location.assign('/u/account/downloads')
                  }} color='secondary'>Click To Download</Button>
                </Box>

            }

              <input type='file' accept='.png, .jpg, .jpeg' style={{display: 'none'}} ref={ref} onChange={(e)=> selectDocument(e)}/>
              <input type='file' accept='.png, .jpg, .jpeg' style={{display: 'none'}} ref={ref2} onChange={(e)=> selectPayslip(e)}/>

                  {
                    
                        doc ? 
                            <Box sx={{
                                my: '1rem',
                                height: '8rem',
                                borderRadius: '10px',
                                border:'1px solid #eee',
                                padding: '1rem',
                                backgroundImage: `url(${doc})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center'
                                }}>
                                <Chip color='primary' size='small' label='Click to upload Standing Order' onClick={() => ref.current.click()} />
                            </Box>
                            :
                            <Box onClick={() => ref.current.click()} sx={{ cursor: 'pointer' , my:'1rem', padding: '2rem', borderRadius: '10px', border: `1px dashed ${grey[300]}`}}>
                              <img src={UploadFile} style={{width: '15%', margin: '0 auto', display: 'block'}} alt='ghcard' />
                              <Typography textAlign={'center'}>Upload Standing Order Form</Typography>
                              <Typography textAlign={'center'} variant='body2' color={'GrayText'}>Max File Size: 1MB</Typography>
                            </Box>
                  
                  }

                  {    
                    slip ? 
                        <Box sx={{
                            my: '1rem',
                            height: '8rem',
                            borderRadius: '10px',
                            border:'1px solid #eee',
                            padding: '1rem',
                            backgroundImage: `url(${slip})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center'
                            }}>
                            <Chip color='primary' size='small' label='Click to upload Payslip/Bank Statement' onClick={() => ref2.current.click()} />
                        </Box>
                        :
                        <Box onClick={() => ref2.current.click()} sx={{ cursor: 'pointer' , my:'1rem', padding: '2rem', borderRadius: '10px', border: `1px dashed ${grey[300]}`}}>
                            <img src={UploadFile} style={{width: '15%', margin: '0 auto', display: 'block'}} alt='ghcard' />
                            <Typography textAlign={'center'}>Bank Statement or PaySlip</Typography>
                            <Typography textAlign={'center'} variant='body2' color={'GrayText'}>Max File Size: 1MB</Typography>
                        </Box>
                  }

            </Box> */}

                <Box mt={2} sx={{height: '14rem', borderRadius: '10px', padding: '1.2rem', overflowY: 'scroll', border: '1px solid lightgrey'}}>
                    <Typography color={'GrayText'} paragraph className={classes.justify}>
                        <strong>DIRECT DEBIT MANDATE TERMS AND OBLIGATIONS:</strong> 
                        <br />
                        <br />
                        <b>a.</b> Forbes assures that the Client/Payer shall obtain immediate reimbursement from   Forbes three (3) days after notification of Forbes by the Client/Payer, in case of an erroneous initiation of a Transfer.<br />
                        <b>b.</b> Forbes categorically assures that Transfers shall conform strictly to the terms of the direct debit Mandate and the conditions governing direct debit payments between the parties concerned.<br />
                        <b>c.</b> The Client/Payer may cancel a Mandate at any time by notifying Forbes and his/her Bank accordingly if there are no outstanding loan payments on the client/payer account to be paid to Forbes. <br />

                    </Typography>
                    <Typography color={'GrayText'} paragraph className={classes.justify}>
                        <strong>AMENDMENT TO A DIRECT DEBIT MANDATE:</strong> 
                        <br />
                        <br />
                        <b>1.</b> The Client/ Payer shall advise Forbes promptly of all technical changes concerning:- 
                                  a. his/her Bank 
                                  b. his/her bank account 
                                  <br />
                        <b>2.</b> The Client/Payer shall advise Forbes and complete a new Mandate where:- 
                                  a. his/her bank account is transferred to another branch of the same bank 
                                  b. he or she changes to another bank <br />
                        <b>3.</b> The Clients’/Payer’s Bank shall use a Mandate Amendment Advice form to notify Forbes of any changes. When produced by automated means the form shall display the relevant information only. Forbes shall also receive Advice of new sorting codes and or account details from banks.<br />

                    </Typography>

                    <Typography color={'GrayText'} paragraph className={classes.justify}>
                        <strong>RIGHTS AND OBLIGATIONS OF FORBES:</strong> 
                        <br />
                        <br />
                        <b>1.</b> Shall first obtain a Mandate from its client/payer before initiating a Transfer on the clients’/Payer’s bank account.<br />
                        <b>2.</b> Shall initiate a Transfer strictly within the terms of the Authority. <br />
                        <b>3.</b> Shall initiate a Transfer within the terms of any Advance Notice given to the client/Payer and ensure that the data on a Transfer shall conform to that advised in the Authority.<br />
                        <b>4.</b> To give seven (7) day’s notice to its Client/Payer and/or a Clients’/Payer’s Bank before making any changes to:- <br />
                                a. it’s name or Regulations 
                                b. information quoted on an Authority <br />
                        <b>5.</b> Ensure that where it is notified that a Mandate  is cancelled or amended, it shall alter its records immediately, in order to ensure that:- <br />
                            a. Transfer is not initiated after receipt of such notice of cancellation. <br/>
                            b. All Transfers initiated in the future incorporate the amendment so notified, in the case of amendment. <br/>
                            c. Retain documentation received from its Clients’/Payers Bank. <br/>
                            d. Payments made under separate Authorities are not merged. <br/>
                    </Typography>
                    <Typography color={'GrayText'} paragraph className={classes.justify}>
                      Each Party undertakes on its own behalf and on behalf of its personnel, not to disclose to any person or third party directly or indirectly any information obtained directly or indirectly as a result of the performance of this agreement.
                    </Typography>
                    <Typography color={'GrayText'} paragraph className={classes.justify}>
                      I the undersigned, hereby authorizes Forbes Microfinance to deduct/withdraw the total loan due from my bank account or from any other account I may transfer this account as instructed or in the event my employer redirects my salary to another account, Forbes Microfinance LTD will have access to deduct/withdraw from same.
                    </Typography>
                  
                  <FormControlLabel style={{ marginTop: '0rem' }} 
                    control={ <Checkbox color='primary' checked={accept} onClick={()=>setAccept(!accept)} /> } 
                    label={ <Typography variant='body1' color='textSecondary'> I hereby accept the Interest Rate and WePay Policy </Typography> }
                  />
                </Box>
          </DialogContent>

        <DialogActions style={{padding: '1rem 2rem'}}>
            <Button disabled={load2} variant='outlined' color='secondary' onClick={onHandleClose}>Cancel</Button>
            <LoadingButton loading={load2} variant='contained' disabled={ accept ? false : true} disableElevation color='secondary' onClick={onLoanRequest} style={{color: '#fff'}}  >Request Loan</LoadingButton>
        </DialogActions>
      </Dialog>

      {/* POPUP */}
      <Dialog open={ currentUser?.isEligible } >
        <DialogContent sx={{position: 'relative', padding: '1rem'}}>
        <IconButton style={{position: 'absolute', top: '2%', right:'3%'}} onClick={()=>popupSettings()}><Close /></IconButton>
        <Box textAlign={'center'}>
            <img src={Award} width='35%' alt='award' />
        </Box>
        <Box textAlign={'center'} marginBottom='2rem' padding='0 2rem'>
          <Typography variant='h6' sx={{fontWeight: 600, fontSize: '1.4rem'}}>You're All Set Now</Typography>
          <Typography color='textSecondary' paragraph variant='body1'>Congratulations!! Your account profile is complete. You are now eligible to request for a LOAN. You have been approve a credit limit of GH¢ {currentUser?.loan ? currentUser?.loan[0]?.limit.toLocaleString() : '0.00'}.</Typography>
          <Button size='large' variant='contained' disableElevation color='secondary' onClick={onPopUpClose}>GET A LOAN NOW</Button>
        </Box>
        </DialogContent>
      </Dialog>

     </div>
    </>
  )
};


const mapStateToProps = (state) => {
  //console.log(state)
  return state
}

export default connect(mapStateToProps, { requestLoan, errorModal, successModal, popupSettings, welcomePopup, submitPopup, uploadPayslip, topupLoan})(PageHeader)
