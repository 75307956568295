import { Avatar, Box, Card, CardContent, CardHeader, Chip, Dialog, Divider, Grid, IconButton, InputAdornment, LinearProgress, styled, TextField, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { CameraAlt, CheckCircle, Schedule,  CalendarToday } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import React, { useRef, useState } from 'react';
import PageHeader from './dashboard/PageHeader';
import { connect, useSelector } from 'react-redux';
import { updateDetails, errorModal, uploadUserPhoto,} from '../../actions/actions';
import Modal from '../../components/Modal';

const StyledCard = styled(Card)(({theme}) => ({
  borderRadius: '15px',
  '&:hover': {
    boxShadow: 'rgb(32 40 45 / 8%) 0px 2px 14px 0px'
  }
}))

const InputField = styled(TextField)(({theme}) => ({
  marginBottom: '.5rem',
  '& label.Mui-focused':{
    color: theme.primaryColorOrange
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: '10px',
  "&.Mui-focused fieldset": {
    border: `1px solid ${theme.primaryColorOrange}`
  }
}
}))

const useStyles = makeStyles(theme => ({
  root : {
    '& *': {
      borderRadius: '8px'
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        border: `1px solid ${theme.primaryColorOrange}`
      }
    }

  },
  caption: {
    '& span': {
      fontSize: '1.1rem',
      fontWeight: 500,
      padding: '.5rem'
    }
  },
  wrapper: {
    padding: '2.5rem'
  },
  field:{
    marginBottom: '1.5rem',
    '& label.Mui-focused':{
      color: theme.primaryColorOrange
    },
    
  },
  btn: {
    width:'12rem',
    background: theme.primaryColorOrange,
    padding: '.8rem 0',
    color: '#fff',
    borderRadius: '8px',
    marginBottom: '2rem',
    '&:hover' : {
      background: theme.secondaryColorDark
    }
  },
  userImage : {
    width: theme.spacing(14),
    height: theme.spacing(14),
    margin: '0 auto',
    marginBottom: '1.5rem',
    borderRadius: '50%',
    
  },
  card: {
    '&:hover': {
        boxShadow: 'rgb(32 40 45 / 8%) 0px 2px 14px 0px'
    }
  },
  upload : {
    minWidth: '350px',
    padding: '.7rem',
    textAlign: 'center',
    border: `1px solid ${grey[300]}`,
    borderRadius: '8px',
    marginBottom: '1.5rem',
    cursor: 'pointer',
    '&:hover' : {
      background: grey[100]
    }
  },
  editIcon : {
    fontSize: '.9rem',
    marginRight: '2rem',
    padding: '0 .5rem',
    borderRadius: '50px',
    background: theme.secondaryColorDark,
    color: '#fff'
  },
  documents: {
    height: '7.5rem',
    backgroundSize: 'cover',
    backgroundPosition:'center',
    border: `1px solid ${grey[300]}`,
    borderRadius: '8px',
    marginBottom: '1rem',
  }
}))

const Profile = (props) => {
  const currentUser = useSelector(state => state.currentUser)
  const { uploadUserPhoto} = props
    const classes = useStyles()
    const[loader, setLoader] = useState(false)
    const pix = useRef()


const uploadPhoto = async(e) => {
  const data = new FormData()
   data.append('photo', e.target.files[0])
  setLoader(true)
  await uploadUserPhoto(data)
  setLoader(false)
  window.location.assign('/u/account/dashboard')
}



  return (
    <>

    <PageHeader title={'User Profile'} link2={'Profile'} user={props.user}/>
    {/* MODAL */}
    {props.modal && <Modal status={props.modal.satus} />}

    <Grid container className={classes.root} spacing={4}>
      <Grid item lg={8} md={12} order={{xs: 2, sm: 2, md: 1, lg: 1}}>
          <StyledCard variant='outlined' elevation={0} className={classes.card} style={{marginBottom: '1.5rem'}}>
            <Box style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <CardHeader title='Personal Details' className={classes.caption} />
            <Chip label={<Typography variant='body2'>{props.user.status}</Typography>} sx={{
                fontSize: '.9rem',
                marginRight: '2rem',
                padding: '0 .5rem',
                borderRadius: '50px',
                background: theme => theme.palette.primary.main,
                color: '#fff'
            }}/>
            </Box>
            <Divider />
            <CardContent sx={{padding: '2.5rem'}}>
            <form >
              <Grid container spacing={3}>
                  <Grid item sm={6} xs={12} >
                  <InputField variant='outlined' label='Fullname' value={props.user?.fullname} fullWidth className={classes.field}
                  InputProps={{ endAdornment: props.user.bankStatus > 0 && props.user.status === 'Verified' ?  
                  <InputAdornment position='end'><CheckCircle fontSize='small' sx={{color: '#f6a200'}} /></InputAdornment> : <InputAdornment position='end'><Schedule fontSize='small' sx={{color: '#f6a200'}} /></InputAdornment>}}
                   />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                  <InputField variant='outlined' label='Gender' value={props.user?.sex} fullWidth 
                  className={classes.field} InputProps={{ endAdornment: props.user.bankStatus > 0 && props.user.status === 'Verified' ?  
                  <InputAdornment position='end'><CheckCircle fontSize='small' sx={{color: '#f6a200'}} /></InputAdornment> : <InputAdornment position='end'><Schedule fontSize='small' sx={{color: '#f6a200'}} /></InputAdornment>}}/>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                  <InputField variant='outlined' label='Date of Birth' value={`${props?.user?.dob}`} fullWidth 
                    className={classes.field} InputProps={{ 
                    startAdornment: <InputAdornment position='start'> <CalendarToday fontSize='small' /> </InputAdornment>,
                    endAdornment: props.user?.bankStatus > 0 && props.user?.status === 'Verified' ?  
                  <InputAdornment position='end'><CheckCircle fontSize='small' sx={{color: '#f6a200'}} /></InputAdornment> : <InputAdornment position='end'><Schedule fontSize='small' sx={{color: '#f6a200'}} /></InputAdornment>}}/>
                </Grid>
                  <Grid item sm={6} xs={12}>
                  <InputField variant='outlined' label='Residence Address' value={props.user?.address} fullWidth 
                  className={classes.field} InputProps={{ endAdornment: props.user.bankStatus > 0 && props.user.status === 'Verified' ?  
                  <InputAdornment position='end'><CheckCircle fontSize='small' sx={{color: '#f6a200'}} /></InputAdornment> : <InputAdornment position='end'><Schedule fontSize='small' sx={{color: '#f6a200'}} /></InputAdornment>}}/>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                  <InputField variant='outlined' label='Digital Address' value={props.user?.digital_address} fullWidth 
                  className={classes.field} InputProps={{ endAdornment: props.user?.bankStatus > 0 && props.user?.status === 'Verified' ?  
                  <InputAdornment position='end'><CheckCircle fontSize='small' sx={{color: '#f6a200'}} /></InputAdornment> : <InputAdornment position='end'><Schedule fontSize='small' sx={{color: '#f6a200'}} /></InputAdornment>}}/>
                  </Grid>

                  <Grid item sm={6} xs={12}>
                  <InputField variant='outlined' label='Phone' value={props.user?.phone} fullWidth 
                  InputProps={{ endAdornment: props.user?.bankStatus > 0 && props.user?.status === 'Verified' ?  
                  <InputAdornment position='end'><CheckCircle fontSize='small' sx={{color: '#f6a200'}} /></InputAdornment> : <InputAdornment position='end'><Schedule fontSize='small' sx={{color: '#f6a200'}} /></InputAdornment>}} 
                  className={classes.field}/>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                  <InputField variant='outlined' label='Occupation' value={props.user?.occupation} fullWidth 
                  className={classes.field} InputProps={{ endAdornment: props.user?.bankStatus > 0 && props.user?.status === 'Verified' ?  
                  <InputAdornment position='end'><CheckCircle fontSize='small' sx={{color: '#f6a200'}} /></InputAdornment> : <InputAdornment position='end'><Schedule fontSize='small' sx={{color: '#f6a200'}} /></InputAdornment>}}/>
                  </Grid>

                  <Grid item sm={6} xs={12}>
                  <InputField variant='outlined' label='Company' value={props.user?.company} fullWidth 
                  className={classes.field} InputProps={{ endAdornment: props.user?.bankStatus > 0 && props.user?.status === 'Verified' ?  
                  <InputAdornment position='end'><CheckCircle fontSize='small' sx={{color: '#f6a200'}} /></InputAdornment> : <InputAdornment position='end'><Schedule fontSize='small' sx={{color: '#f6a200'}} /></InputAdornment>}}/>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                  <InputField variant='outlined' label='Company Address' value={props.user?.companyAddress} fullWidth  
                  className={classes.field} InputProps={{ endAdornment: props.user?.bankStatus > 0 && props.user?.status === 'Verified' ?  
                  <InputAdornment position='end'><CheckCircle fontSize='small' sx={{color: '#f6a200'}} /></InputAdornment> : <InputAdornment position='end'><Schedule fontSize='small' sx={{color: '#f6a200'}} /></InputAdornment>}}/>
                  </Grid>

                <Grid item sm={6} xs={12}>
                <InputField variant='outlined' label='Monthly Salary' value={`${parseInt(props.user?.monthlySalary).toLocaleString()}`} fullWidth 
                className={classes.field} InputProps={{ 
                  startAdornment: <InputAdornment position='start'>GH¢</InputAdornment>,
                  endAdornment: props.user.bankStatus > 0 && props.user.status === 'Verified' ?  
                  <InputAdornment position='end'><CheckCircle fontSize='small' sx={{color: '#f6a200'}} /></InputAdornment> : <InputAdornment position='end'><Schedule fontSize='small' sx={{color: '#f6a200'}} /></InputAdornment>}}/>
                </Grid>
                <Grid item sm={6} xs={12}>
                <InputField variant='outlined' label='National ID' value={`${props.user?.nationalID?.idNumber}`} fullWidth 
                className={classes.field} InputProps={{ 
                  endAdornment: props.user.bankStatus > 0 && props.user?.status === 'Verified' ?  
                  <InputAdornment position='end'><CheckCircle fontSize='small' sx={{color: '#f6a200'}} /></InputAdornment> : <InputAdornment position='end'><Schedule fontSize='small' sx={{color: '#f6a200'}} /></InputAdornment>}}/>
                </Grid>

                <Grid item sm={6} xs={12}>
                  <InputField variant='outlined' label='ID Issue Date' value={`${props?.user?.nationalID?.id_issue_date}`} fullWidth 
                    className={classes.field} InputProps={{ 
                    startAdornment: <InputAdornment position='start'> <CalendarToday fontSize='small' /> </InputAdornment>,
                    endAdornment: props.user?.bankStatus > 0 && props.user?.status === 'Verified' ?  
                  <InputAdornment position='end'><CheckCircle fontSize='small' sx={{color: '#f6a200'}} /></InputAdornment> : <InputAdornment position='end'><Schedule fontSize='small' sx={{color: '#f6a200'}} /></InputAdornment>}}/>
                </Grid>

                <Grid item sm={6} xs={12}>
                  <InputField variant='outlined' label='ID Expiry Date' value={`${props?.user?.nationalID?.id_expiry_date}`} fullWidth 
                    className={classes.field} InputProps={{ 
                    startAdornment: <InputAdornment position='start'> <CalendarToday fontSize='small' /> </InputAdornment>,
                    endAdornment: props.user?.bankStatus > 0 && props.user?.status === 'Verified' ?  
                  <InputAdornment position='end'><CheckCircle fontSize='small' sx={{color: '#f6a200'}} /></InputAdornment> : <InputAdornment position='end'><Schedule fontSize='small' sx={{color: '#f6a200'}} /></InputAdornment>}}/>
                </Grid>
                {
                  props.user?.accountNo && 
                  <Grid item sm={6} xs={12}>
                    <InputField variant='outlined' label='Account No.' value={`${props.user?.accountNo}`} fullWidth 
                      className={classes.field} InputProps={{ 
                      endAdornment: props.user.bankStatus > 0 && props.user?.status === 'Verified' ?  
                    <InputAdornment position='end'><CheckCircle fontSize='small' sx={{color: '#f6a200'}} /></InputAdornment> : <InputAdornment position='end'><Schedule fontSize='small' sx={{color: '#f6a200'}} /></InputAdornment>}}/>
                  </Grid>
                }
                
                

            </Grid>
            </form>

            </CardContent>
          </StyledCard>
          
          
      </Grid>

      {/* PROFILE PHOTO */}
      <Grid item lg={4} md={12} sm={12} xs={12} order={{xs: 1, sm: 1, md: 2, lg: 2 }}>
        <StyledCard elevation={0} variant='outlined' className={classes.card} style={{marginBottom: '1.5rem'}}>
          
          <Box style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <input type='file' accept='.png, .jpg' style={{display: 'none'}} ref={pix} onChange={(e)=> uploadPhoto(e)}/>
            <CardHeader title='Profile Photo' className={classes.caption} />
          </Box>

          <Divider />

          <CardContent sx={{padding: '2.5rem'}} style={{textAlign: 'center'}}>
              <Box sx={{position: 'relative', width: 'fit-content', margin: '0 auto'}}>
                <Avatar sx={{width: '7rem', height: '7rem', margin: '0 auto', marginBottom: '1rem'}} 
                src={props.user.photo ? props.user.photo : null} />
                <IconButton  
                  sx={{boxShadow: '0 2px 10px rgba(0,0,0, 20%)', 
                  position: 'absolute', bottom: 0, right: '-5%', 
                  bgcolor: 'light.main', ':hover': {bgcolor: 'light.main'}}} 
                  onClick={() => {
                    if(currentUser.emailStatus === 0){
                        props.errorModal('Sorry, please verify your email address')
                        return
                    }
                    if(currentUser.bankStatus === 0){
                        props.errorModal('Sorry, please provide your bank details')
                        return
                    }
                    pix.current.click()
                  }}>
                  <CameraAlt fontSize='small' />
                </IconButton>
              </Box>
              <Typography paragraph variant='body2' color='textSecondary' >
                 Passport Size Photo
              </Typography>
          </CardContent>
        </StyledCard>

       
      </Grid>
    </Grid>

    {/* LOADER */}
      <Dialog open={loader} >
          <Box padding={'2.5rem'} textAlign={'center'}>
              {/* <img src={User} alt='update' width={'25%'}/> */}
              <Typography paragraph>Updating your account. Please wait...</Typography>
              <LinearProgress variant='indeterminate' />
          </Box>
      </Dialog>
  </>
  )
};

const mapStateToProps = (state) => {
  //console.log(state)
  return state
}

export default connect(mapStateToProps, {updateDetails, errorModal, uploadUserPhoto})(Profile);
