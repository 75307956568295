import React from 'react'
import { styled } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';

const StyledButton = styled(LoadingButton)(({theme}) => ({
    padding: '.7rem 1rem',
    borderRadius: '10px'
}))

const RoundButton = ({ text, onClick, variant, disableElevation, fullWidth, size, sx, color, loading }) => {
  return (
    <StyledButton loading={loading} sx={sx} color={color} onClick={onClick} variant={variant} disableElevation={disableElevation} fullWidth={fullWidth} size={size}>
        {text}
    </StyledButton>
  )
}

export default RoundButton