import { Container, Grid, Hidden, InputAdornment, Link, styled, TextField, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import LoginImage from  '../assets/images/login.svg'
import Modal from '../components/Modal';
import { connect, useDispatch } from 'react-redux';
import { loginUser, successModal } from '../actions/actions';
import { useLocation } from 'react-router-dom';
import 'animate.css'
import RoundButton from '../components/RoundButton';
import { Call } from '@mui/icons-material';

const InputField = styled(TextField)(({theme}) => ({
    marginBottom: '1.5rem',
    '& *': {
      borderRadius: '8px'
    },
    '& label.Mui-focused':{
      color: theme.primaryColorOrange
    },
    "& .MuiOutlinedInput-root": {
        borderRadius: '10px',
      "&.Mui-focused fieldset": {
        border: `1px solid ${theme.primaryColorOrange}`
      }
    }
}))

const useStyles = makeStyles(theme => ({
  root : {
    height: '60vh',
    marginTop: '2rem',
    '& .MuiDivider-root': {
      flexGrow: 1
    }
  },
  wrapper : {
    [theme.breakpoints.up('lg')] : {
      margin : '0 0 4rem 6rem'
    },
    display: 'flex',
    flexDirection: 'column',
    padding: '3.2rem',
    borderRadius: '16px',
    boxShadow: '-24px 24px 72px 8px rgb(145 158 171 / 24%)',
    '& a': {
      color: theme.primaryColorOrange,
      fontWeight: 400
      
    }
  },
  image : {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  title : {
    marginBottom: '.5rem',
    fontWeight: 500
  },
  field : {
    marginBottom: '1.5rem',
    borderRadius: '1.6rem',
    '& *': {
      borderRadius: '8px'
    },
    '& label.Mui-focused':{
      color: theme.primaryColorOrange
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        border: `1px solid ${theme.primaryColorOrange}`
      }
    }
  },
  btn: {
    background: theme.primaryColorOrange,
    padding: '1rem 0',
    color: '#fff',
    borderRadius: '8px',
    marginBottom: '2rem',
    '&:hover' : {
      background: theme.secondaryColorDark
    }
  },
  btnGoogle: {
    padding: '1rem 0',
    background: grey[300],
    borderRadius: '8px'
  },
  flex : {
    marginBottom: '2rem',
    display: 'flex',
    gap: '1rem',
    justifyContent: 'center',
    alignItems: 'center'
  },
  fieldIcon: {
    color: grey[400],
    cursor: 'pointer',
  },
  toolbarHeight: theme.mixins.toolbar,

}))



const Login = (props) => {
  const {currentUser, successModal} = props
  const modalDispatch = useDispatch()
  const classes = useStyles()
  const [load, setLoad] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [phone, setPhone] = React.useState('')
  const path= useLocation();
  // const token = path.search.split('=')[1]
  //console.log(path)


  useEffect(()=>{
    if(currentUser === false && path.search !== ''){
      successModal('Activating your account. Please login to complete')
      return
    }

  },[currentUser, path, successModal])
  

  const onFormSubmit = async() => {
    if(phone === '' || phone.length < 10){
      setError(true)
      modalDispatch({ type: 'ERROR_MODAL', payload: 'Invalid. Phone number must be 10 characters' })
      return
    }
    // call action creators
      setLoad(true)
      await props.loginUser({phone})
      setLoad(false)
  }


  return (
    <>
      <div className={classes.toolbarHeight} ></div>
      <Container className={classes.root}>

      {/* MODAL  */}
      { props.modal && <Modal status={props.modal.status} />}
        <Grid container spacing={5}>
          <Hidden smDown>
            <Grid item xs={12} sm={6} md={6} lg={6} className={`${classes.image} animate__animated animate__fadeIn`}>
              <img style={{width: '90%'}} src={LoginImage} alt='login-icon' />
            </Grid>
          </Hidden>

          <Grid item xs={12} sm={12} md={6} lg={6} style={{display: 'flex', justifyContent: 'center'}} className='animate__animated animate__zoomIn' >
            <div className={`${classes.wrapper}`}>
              <Typography sx={{fontWeight: 500, marginBottom: '10px'}} variant='h4'>Login</Typography>
              <Typography style={{marginBottom: '2.5rem'}} paragraph color='textSecondary'>Don't have an account? <Link href='/auth/register'>Create Account</Link></Typography>

                <InputField variant='outlined' error={error} 
                  inputProps={{maxLength: 10}} autoComplete={'false'} label='Phone Number' 
                  InputProps={{ endAdornment: <InputAdornment position='start'><Call fontSize='small' /></InputAdornment>}}
                  placeholder='024********' value={phone} onChange={(e)=>setPhone(e.target.value)} fullWidth />
                <RoundButton onClick={onFormSubmit} loading={load} sx={{mb: '1rem'}} text='Login' variant={'contained'} color='secondary' disableElevation type='submit' fullWidth />
                <Typography textAlign={'center'} paragraph > <Link href='/auth/forgotpassword' underline='none'>Forgot Password </Link> </Typography>

            </div>
          </Grid>
        </Grid>
      </Container>
    </>

  ) 
};


 const mapStateToProps = (state) => {
   return state
 }

export default connect(mapStateToProps, {loginUser, successModal})(Login);
