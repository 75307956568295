import { Search, CreditCard } from '@mui/icons-material';
import { Avatar, Card, Box, CardContent, Chip, Grid, InputAdornment, TextField, Typography, Button, Hidden } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React,  {useState} from 'react';
import { makeStyles } from '@mui/styles';
import PageHeader from './dashboard/PageHeader'
import Wallet from '../../assets/images/wallet.png'
import { getUserCard } from '../../actions/actions';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';


const useStyles = makeStyles( theme => ({
  root : {
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px',
      "&.Mui-focused fieldset": {
        border: `1px solid ${theme.backgroundPrimary}`
      }
    }
  },
  addBtn: {
    marginLeft: 'auto',
    height: '45px',
    color: '#fff',
    background: theme.primaryColorOrange,
    padding: '0 15px',
    borderRadius: '10px',
    '&:hover' : {
      background: theme.secondaryColorDark
    }
  },
  title : {
    fontSize: '1.8rem',
    fontWeight: 700
  },
  large : {
    width: theme.spacing(6),
    height: theme.spacing(6),
    background: theme.primaryColorOrange,
    borderRadius: '10px',
    marginBottom: '1rem',
    color: '#fff'
  }, 
  card : {
    background: theme.secondaryColorDark,
    '& h6, p': {
      color: '#fff'
    }
  }

}))


const Payment = (props) => {
  const {user, getUserCard} = props
  const classes = useStyles()
  const [pageSize, setPageSize] = useState(5)

  console.log(user)

  React.useEffect(() => {
    if(user.status === 'Approved'){
      getUserCard();
    }
  },[getUserCard, user])

  const pendingLoans = user?.loan[0]?.loans?.filter(el => el.status === 'Pending')
  const activeLoans = user?.loan[0]?.loans?.filter(el => el.isActive)
  const totalPaid = activeLoans?.map(ln => ln.perMonth)
  const totalAmt = totalPaid?.reduce((prev, curr) => (prev + curr), 0)

  const payColumns = [
    {field: 'userID', headerName: 'Customer Name', flex: 1, renderCell: (params) => `${props.user.fullname}` }, 
    {field: 'createdAt', headerName: 'Date of Payment', flex: 1, renderCell: (params) => `${new Date(params.value).toDateString()} at ${new Date(params.value).toLocaleTimeString()}` }, 
    {field: 'amount', headerName: 'Amount Paid', flex: 1, renderCell: (params) => { return `GH¢ ${params.value}.00`} },
    {field: 'status', headerName:' Status', flex: 1, renderCell: (params) => { return <Chip disabled label={params.value} />} },
  ]

  const loanColumns = [
    {field: 'amount', headerName: 'Loan Amount', flex: 1, renderCell: (params) => `GH¢ ${params.value}.00` }, 
    {field: 'date', headerName: 'Date/Time', flex: 1.5, renderCell: (params) => `${new Date(params.value).toDateString()} at ${new Date(params.value).toLocaleTimeString()}` }, 
    {field: 'perMonth', headerName: 'Monthly Pay', flex: 1, renderCell: (params) => `GH¢ ${params.value}` },
    {field: 'duration', headerName: 'Duration', flex: 1, renderCell: (params) => { return `${params.value} Months` } },
    {field: 'interest', headerName: 'Interest', flex: .5, renderCell: (params) => { return `${params.value}%` } },
    {field: 'status', headerName:' Status', flex: 1, renderCell: (params) => { return <Chip label={params.value} />} },
  ]

  const mobileCol = [
    {field: 'amount', headerName: 'Loan Amount', flex: 1, renderCell: (params) => `GH¢ ${params.value}.00` }, 
    // {field: 'date', headerName: 'Date/Time', flex: 1.5, renderCell: (params) => `${new Date(params.value).toDateString()} at ${new Date(params.value).toLocaleTimeString()}` }, 
    // {field: 'perMonth', headerName: 'Monthly Pay', flex: 1, renderCell: (params) => `GH¢ ${params.value}.00` },
    {field: 'duration', headerName: 'Duration', flex: 1, renderCell: (params) => { return `${params.value} Months` } },
    // {field: 'interest', headerName: 'Interest', flex: .5, renderCell: (params) => { return `${params.value}%` } },
    {field: 'status', headerName:' Status', flex: 1, renderCell: (params) => { return <Chip size='small' label={params.value} />} },
  ]



  const renderPage = () => {
    if(props.user?.loan.length !== 0 && props.user?.loan[0]?.loans?.length !== 0){
      return <>
        <Grid container spacing={2}>

          {/* loan amount */}
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <Card sx={{
              background: theme => theme.secondaryColorDark,
                '& h6, p': {
                  color: '#fff'
                }
            }} variant='outlined' style={{borderRadius: '10px'}}>
              <CardContent style={{padding: '2rem', position: 'relative'}}>
                <Avatar sx={{
                  width: theme => theme.spacing(6),
                  height: theme => theme.spacing(6),
                  background: theme => theme.primaryColorOrange,
                  borderRadius: '10px',
                  marginBottom: '1rem',
                  color: '#fff'
                }}><CreditCard /></Avatar>
                <Typography variant='h6' sx={{
                  fontSize: '2rem',
                  fontWeight: 600
                }}>{ `GH¢ ${pendingLoans?.length > 0 ? pendingLoans[0]?.amount?.toLocaleString() : "0.00"}`}</Typography>
                <Typography variant='body2' color='textSecondary'>{ props.user.loan[0]?.loans[0].status === 'Pending' ? 'Requested' : 'Recent'} Loan</Typography>
                { pendingLoans?.length > 0 && <Chip label={pendingLoans[0]?.status} style={{position: 'absolute', top: '20%', right: '7%'}} color='primary' /> }
              </CardContent>
            </Card>
          </Grid>

          {/* Outstanding Loan */}
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <Card sx={{
              background: theme => theme.secondaryColorDark,
                '& h6, p': {
                  color: '#fff'
                }
            }} variant='outlined' style={{borderRadius: '10px'}}>
              <CardContent style={{padding: '2rem'}}>
              <Avatar sx={{
                width: theme => theme.spacing(6),
                height: theme => theme.spacing(6),
                background: theme => theme.primaryColorOrange,
                borderRadius: '10px',
                marginBottom: '1rem',
                color: '#fff'
              }}><CreditCard /></Avatar>
                <Typography variant='h6' sx={{
                  fontSize: '2rem',
                  fontWeight: 600
                }}> { props.card.amount ? `GH¢ ${props.card.amount?.toLocaleString()}` : 'GH¢ 0.00'}</Typography>
                <Typography variant='body2' color='textSecondary'>Outstanding Loan</Typography>
              </CardContent>
            </Card>
          </Grid>
          
          {/* Pay per month */}
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <Card sx={{
              background: theme => theme.secondaryColorDark,
                '& h6, p': {
                  color: '#fff'
                }
            }} variant='outlined' style={{borderRadius: '10px'}}>
              <CardContent style={{padding: '2rem'}}>
              <Avatar sx={{
                width: theme => theme.spacing(6),
                height: theme => theme.spacing(6),
                background: theme => theme.primaryColorOrange,
                borderRadius: '10px',
                marginBottom: '1rem',
                color: '#fff'
              }}><CreditCard /></Avatar>
                <Typography variant='h6' sx={{
                  fontSize: '2rem',
                  fontWeight: 600
                }}>{`GH¢ ${totalAmt?.toLocaleString()}` } </Typography>
                <Typography variant='body2' color='textSecondary'>Payment Per Month</Typography>
              </CardContent>
            </Card>
          </Grid>

            {/* Loan Paid */}
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <Card sx={{
              background: theme => theme.secondaryColorDark,
                '& h6, p': {
                  color: '#fff'
                }
            }} variant='outlined' style={{borderRadius: '10px'}}>
              <CardContent style={{padding: '2rem'}}>
              <Avatar sx={{
                width: theme => theme.spacing(6),
                height: theme => theme.spacing(6),
                background: theme => theme.primaryColorOrange,
                borderRadius: '10px',
                marginBottom: '1rem',
                color: '#fff'
              }}><CreditCard /></Avatar>
                <Typography variant='h6' sx={{
                  fontSize: '2rem',
                  fontWeight: 600
                }}>{`GH¢ ${props.payment.total.toLocaleString()}`}</Typography>
                <Typography variant='body2' color='textSecondary'>Loan Paid</Typography>
              </CardContent>
            </Card>
          </Grid>

          </Grid>

          {/* LOANS */}
          <Card variant='outlined' style={{ borderRadius: '10px', width: '100%', marginTop: '2rem'}} className={classes.root}>
            <CardContent style={{ padding:'30px' }}>
                <Box marginBottom={'1rem'} sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', '& > span': {
                  display: 'flex', justifyContent: 'space-between', gap: '.7rem', alignItems: 'center'
                }}}>
                  <Typography variant='h5'>All Loans</Typography>
                  <Hidden smDown>
                    <span>
                      <Typography>Credit Limit</Typography>
                      <Button size='large' variant='contained' disableElevation color='secondary'>GH¢ {user?.loan[0]?.limit.toLocaleString()}</Button>
                    </span>
                  </Hidden>
                </Box>
                <TextField variant='outlined' style={{ marginBottom: '20px'}}
                placeholder='Search for loan/date' fullWidth
                InputProps={{ 
                  startAdornment: <InputAdornment position='start'><Search fontSize='small' /> </InputAdornment>
                  }}/>
                <DataGrid autoHeight disableSelectionOnClick
                  pagination rows={ user?.loan[0]?.loans} rowsPerPageOptions={[5, 10, 15, 20]}
                  rowHeight={80} columns={ isMobile ? mobileCol : loanColumns} getRowId={(row) => row._id}
                  pageSize={pageSize} checkboxSelection={ isMobile ? false : true}
                  onPageSizeChange={(newSize)=> setPageSize(newSize)}
                  />

            </CardContent>
          </Card>

          {/* PAYMENTS */}

          <Card variant='outlined' style={{ borderRadius: '10px', width: '100%', marginTop: '2rem'}} className={classes.root}>
            <CardContent style={{ padding:'30px' }}>
                <Box marginBottom={'1rem'}>
                  <Typography variant='h5'>Payments</Typography>
                </Box>
                <TextField  variant='outlined' style={{ marginBottom: '20px'}}
                placeholder='Find a payment' fullWidth
                InputProps={{ 
                  startAdornment: <InputAdornment position='start'><Search fontSize='small' /> </InputAdornment>
                  }}/>
                <DataGrid autoHeight 
                  pagination rows={props.payment.items.filter(pay => pay.status === 'confirmed')} rowsPerPageOptions={[5, 10, 15, 20]}
                  rowHeight={120} columns={payColumns} 
                  pageSize={pageSize} checkboxSelection 
                  onPageSizeChange={(newSize)=> setPageSize(newSize)}
                  />

            </CardContent>
          </Card>
      </>
    }
    return <>
      <Box padding={'3rem'} textAlign='center'>
        <img src={Wallet} width='30%' alt='wallet' style={{marginBottom: '1rem'}} />
        <Typography variant='h6' gutterBottom>Looks like your wallet is empty</Typography>
        <Typography color='textSecondary' gutterBottom>Complete your account status and get verified to be eligible for a LOAN</Typography>
          <Button size='large' variant='contained' color='secondary' sx={{ mt: '2rem'}}>GET A LOAN</Button>
      </Box>
    </>
  }

  return (
    <>
      <PageHeader title={'Loan & Payments'} link2={'payment'} user={props.user} />

      {renderPage()}
      
    </>
  )
};

const mapStateToProps = (state) => {
 //console.log(state)
  return state
}


export default connect(mapStateToProps, {getUserCard})(Payment)
