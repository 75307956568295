import { alpha, Avatar, Box, Chip, Container, Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
// import { makeStyles } from '@mui/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { AccountBox, Dashboard, Download, FileOpen, Forum, Notifications, Payment, PowerSettingsNew, Receipt, Settings } from '@mui/icons-material';
import { useState } from 'react';
import {connect} from 'react-redux'
import { logoutUser } from '../../../actions/actions';

const drawerWidth = 260;

// const useStyles = makeStyles((theme) => ({
//   root : {
//     '& .MuiDivider-root': {
//         background: alpha(theme.primaryColorBlue, 0.2)
//     },
//     },
//   logo : {
//     fontSize: '25px',
//     fontWeight: 100,
//     textAlign: 'center',
//     color: '#fff',
//     padding: '5px 0',
//     margin: '10px 0',
//     '& span': {
//       fontWeight: 600,
//       color: theme.primaryColorOrange
//     }
//   },
//   activeMenu : {
//     background: alpha(theme.primaryColorBlue, 0.2)
//   },

//   menuTitle :{
//     color: 'rgb(107, 114, 128)', 
//   },
//   menuList: {
//     margin: '10px 0',
//   },
//   menuItem : {
//     borderRadius: '8px',
//     gap: 15,
//     margin: '5px 0',
//     '&:hover' :{
//       background: alpha(theme.primaryColorBlue, 0.2)
//     },
//   },
//   menuIcon: {
//     fontSize: '13px',
//     color: '#9ca3af',
//     minWidth: 'auto'
//   },
//   subMenuIcon: {
//     fontSize: '20px',
//     color: '#9ca3af',
//     minWidth: 'auto'
//   },
//   menuText : {
//     '& p': {
//       color: "#9ca3af"
//     }
//   },
//   subMenuText : {
//     '& p': {
//       color: "#9ca3af",
//       fontSize: '14px',
//       marginLeft: '30px'
//     }
//   },
//   userProfile : {
//     display: 'flex',
//     flexDirection: 'column',
//     textAlign: 'center',
//     justifyContent: 'center',
//     alignItems: 'center',
//     padding: '30px 0'
//   },
//   userImage : {
//     width: theme.spacing(12),
//     height: theme.spacing(12),
//     marginBottom: '10px',
//   },
//   userName : {
//     color: '#fff'
//   }, 
//   userTitle : {
//     color: 'rgb(107, 114, 128)'
//   },
//   chip: {
//     height: '20px',
//     '& .MuiChip-label':{
//       padding: '2px 7px',
//       fontSize: '10px'
//     }
//   },
//     // necessary for content to be below app bar
//     toolbarHeight: theme.mixins.toolbar,
// }))


const SideBar = (props) => {

  const [openMenu1, setOpenMenu1] = useState(false)
  const [openMenu2, setOpenMenu2] = useState(false)

  const pathname = useLocation().pathname
  // const classes = useStyles(props)
  const navigate = useNavigate()

const menuItemsGeneral = [
  { name: 'Overview', icon: <Dashboard fontSize='small' />, path: '/u/account/dashboard' },
  { name: 'Loan/Payment', icon: <Receipt fontSize='small' />, path: '/u/account/payment' },
  { name: 'Transactions', icon: <Payment fontSize='small' />, path: '/u/account/transactions' },
]

const menuItemsManagement = [
  { name: 'Account', icon: <AccountBox fontSize='small' />, path: '/u/account/profile' },
  { name: 'Documents', icon: <FileOpen fontSize='small' />, path: '/u/account/documents' },
  { name: 'Notifications', icon: <Notifications fontSize='small' />, path: '/u/account/notifications' },
  { name: 'Downloads', icon: <Download fontSize='small' />, path: '/u/account/downloads' },
  { name: 'Settings', icon: <Settings fontSize='small' />, path: '/u/account/settings' },
]

const menuItemsSupport = [
  { name: 'Support', icon: <Forum fontSize='small' />, path: '/u/account/support' },
  { name: 'Logout', icon: <PowerSettingsNew fontSize='small' />, path: '/logout' },
]

const menuDropDown = (item) => {
  if(item.sub && item.name === 'Store'){
    setOpenMenu2(!openMenu2)
    return
  }
  if(item.sub && item.name === 'Users'){
    setOpenMenu1(!openMenu1)
    return
  }
  else{
    navigate(item.path)
  }
}

const onLogout =() => {
  props.logoutUser()
}


  return (
    <Drawer variant={props.variant} 
        classes={{ paper: drawerWidth }} 
        sx={{ width: drawerWidth, '& .MuiDrawer-paper': {
          backgroundColor: '#24324d',
          width: drawerWidth
        } }}
        open={props.open} onClose={props.close} 
        ModalProps={{ keepMounted: true}}
        >
            <Typography sx={{
                fontSize: '25px',
                fontWeight: 100,
                textAlign: 'center',
                color: '#fff',
                padding: '5px 0',
                margin: '10px 0',
                '& span': {
                  fontWeight: 600,
                  color: 'primary.main'
              }}} variant='h4'>WePay<span>GH</span></Typography>
            <Divider sx={{bgcolor: alpha('#fff', .2)}}  />
            <Container>
              <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '30px 0'
              }}>
                <Avatar sx={{
                  width: '6rem',
                  height: '6rem',
                  marginBottom: '10px', border: theme => `3px solid ${theme.palette.primary.main}`
                  }} src={props.user.photo && props.user.photo} />
                <Typography variant='h6' sx={{color: '#fff'}}>{props.user.fullname}</Typography>
                <Typography variant='body2' color='textSecondary' sx={{color: 'rgb(107, 114, 128)'}} noWrap>{props.user.email}</Typography>
                <Typography variant='body2' color='textSecondary' sx={{color: 'rgb(107, 114, 128)'}}>Customer</Typography>
              </Box>
            </Container>
            <Divider sx={{bgcolor: alpha('#fff', .2)}}  />

            <Container >
                <List>
                  <Typography sx={{color: 'light.main'}} mt={3} variant='body2'>GENERAL</Typography>
                    { 
                      menuItemsGeneral.map((item, index) => {
                      return (
                        <ListItem onClick={()=> { navigate(item.path); props.close() } } key={item.name} button sx={{
                            alignItems:'center',
                            borderRadius: '8px',
                            background: pathname === item.path ? theme => alpha(theme.primaryColorBlue, 0.5) : null,
                            margin: '5px 0',
                            color: '#fff',
                            '&:hover' :{
                              background: theme=>alpha(theme.primaryColorBlue, 0.2)
                            },
                        }} >
                          <ListItemIcon sx={{minWidth: '30px', color: 'light.main'}}>{item.icon}</ListItemIcon>
                          <ListItemText sx={{'& span': {fontSize: '.8rem' }}}>{item.name}</ListItemText>
                            { index > 0 && <Chip size='small' label='new' color='primary' sx={{height: '1.5rem', width: '2.5rem', fontSize: '10px', marginLeft: 'auto'}} />}
                          
                        </ListItem>
                        )
                      })
                    }
                </List>
                <List >
                <Typography  sx={{color: 'light.main'}} mt={2} variant='body2'>MANAGEMENT</Typography>
                    { 
                      menuItemsManagement.map((item) => {
                      return (
                        <div key={item.name} id={item.name.toLowerCase()}>
                          <ListItem onClick={()=> { menuDropDown(item); props.close() } }  
                            button sx={{
                              alignItems:'center',
                              borderRadius: '8px',
                              background: (pathname === item.path) || (item.sub && pathname.startsWith(item.path)) ? theme => alpha(theme.primaryColorBlue, 0.5) : null,
                              margin: '5px 0',
                              color: '#fff',
                                '&:hover' :{
                                  background: theme=>alpha(theme.primaryColorBlue, 0.2)
                                },
                              }}
                            >
                            <ListItemIcon sx={{minWidth: '30px', color: 'light.main'}}>{item.icon}</ListItemIcon>
                            <ListItemText sx={{'& span': {fontSize: '.8rem' }}} >{item.name}</ListItemText>
                           
                          </ListItem>
                           
                        </div>
                        )
                      })
                    }
                </List>
                <List >
                <Typography  sx={{color: 'light.main'}} variant='body2'>SUPPORT</Typography>
                    { menuItemsSupport.map((item) => {
                      return (
                        <ListItem onClick={( item.name === 'Logout' ? ()=>onLogout() : ()=>navigate(item.path) )} 
                          key={item.name} button sx={{
                            alignItems:'center',
                            borderRadius: '8px',
                            background: pathname === item.path ? theme => alpha(theme.primaryColorBlue, 0.5) : null,
                            margin: '5px 0',
                            color: '#fff',
                            '&:hover' :{
                              background: theme=>alpha(theme.primaryColorBlue, 0.2)
                            },
                        }} >
                          <ListItemIcon sx={{minWidth: '30px', color: 'light.main'}}>{item.icon}</ListItemIcon>
                          <ListItemText sx={{'& span': {fontSize: '.8rem' }}}>{item.name}</ListItemText>
                        </ListItem>
                        )
                      })
                    }
                </List>
              </Container>
    </Drawer>
  )
};

const mapStateToProps = (state) => {
  return state
}

export default connect(mapStateToProps, {logoutUser})(SideBar);
