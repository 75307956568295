import { Box, Button, Card, CardContent, Chip, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import { Search, Visibility } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import { DataGrid } from '@mui/x-data-grid';
import React, { useState } from 'react'
import PageHeader from './dashboard/PageHeader'
import {connect} from 'react-redux'
import { markAsRead } from '../../actions/actions'
import { isMobile } from 'react-device-detect'
import { getUserNotifications } from '../../actions/actions';
import { useEffect } from 'react';

const useStyles = makeStyles(theme => ({

}));


const Notifications = (props) => {
  const { getUserNotifications } = props
    const classes = useStyles()
    const [pageSize, setPageSize] = useState(10)
    const [open, setOpen] = useState(false)
    const [notify, setNotify] = useState({})

    useEffect(()=>{
      getUserNotifications()
    },[getUserNotifications])

    const viewNotification =(id)=>{
        const view = props.notifications.filter(item => item.id === id);
        setNotify(view[0])
        setOpen(true)
        props.markAsRead(id)
    }

  const columns = [
    {field: 'title', headerName: 'Title', flex: 1}, 
    {field: 'body', headerName: 'Message',flex: 1.5}, 
    {field: 'createdAt', headerName: 'Date & Time',flex: 1, renderCell: (params) => { return `${new Date(params.value).toDateString()} at ${new Date(params.value).toLocaleTimeString()}`} },
    {field: 'status', headerName:' Status',flex: .5, renderCell: (params) => { return <Chip disabled label={params.value} />} },
    {field: 'id', headerName:' Action',flex: .5, renderCell: (params) => { return <><IconButton onClick={()=>viewNotification(params.value)} ><Visibility fontSize='small' /></IconButton> </>}}
  ]

  return (
    <div>
        <PageHeader title={'Notifications'} link2={'notifications'} />

        <Card variant='outlined' style={{ borderRadius: '10px'}} className={classes.root}>
        <CardContent style={{ padding:'20px' }}>
            <TextField  variant='outlined' style={{ marginBottom: '20px'}}
            placeholder='Find a notification' fullWidth
            InputProps={{ 
              startAdornment: <InputAdornment position='start'><Search fontSize='small' /> </InputAdornment>
              }}/>
              {
                isMobile ? 
                  <Box>
                    {
                      props.notifications?.map(item => {
                        return (
                          <Box key={item._id} sx={{padding: '10px', mb: 2, border: '1px solid lightgrey', borderRadius: '8px'}}>
                            <Typography variant='h6' sx={{fontSize: '1rem'}}>{item.title}</Typography>
                            <Typography variant='body2' color={'GrayText'}>{item.body}</Typography>
                            {/* <Typography variant='body2' color={'GrayText'}>{new Date(item.createdAt).toDateString()} </Typography> */}
                          </Box>
                        )
                      })
                    }

                  </Box>
                  :
                  <DataGrid autoHeight isRowSelectable={(GridRowParams)=> false}
                    pagination rows={props.notifications} rowsPerPageOptions={[10, 15, 20]}
                    rowHeight={70} columns={columns} 
                    pageSize={pageSize} checkboxSelection 
                    onPageSizeChange={(newSize)=> setPageSize(newSize)}
                  />
              }

        </CardContent>
      </Card>

      <Dialog open={open} maxWidth='xs'>
          <DialogTitle>
              <Typography style={{fontSize: '1.3rem', fontWeight: 600}}>{notify.title}</Typography>
              <Typography variant='body2' color='textSecondary'>{`${new Date(notify.createdAt).toDateString()} at ${new Date(notify.createdAt).toLocaleTimeString()}`}</Typography>
          </DialogTitle>
          <DialogContent dividers>
            <Typography variant='body1'>{notify.body}</Typography>
          </DialogContent>
          <DialogActions>
              <Button onClick={()=>setOpen(false)}>Close</Button>
          </DialogActions>
      </Dialog>
    </div>
  )
}

const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps, {markAsRead, getUserNotifications})(Notifications)