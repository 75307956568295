import { Box, Button, Container, FormControlLabel, Grid, InputAdornment, Link, TextField, Typography, Checkbox, Hidden, styled, MenuItem } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import React, { useReducer, useState } from 'react';
import LoginImage from  '../assets/images/login.svg'
import { connect } from 'react-redux';
import { registerUser, errorModal } from '../actions/actions';
import Modal from '../components/Modal';
import 'animate.css'
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const InputField = styled(TextField)(({theme}) => ({
  marginBottom: '1.5rem',
  '& *': {
    borderRadius: '8px'
  },
  '& label.Mui-focused':{
    color: theme.primaryColorOrange
  },
  "& .MuiOutlinedInput-root": {
      borderRadius: '10px',
    "&.Mui-focused fieldset": {
      border: `1px solid ${theme.primaryColorOrange}`
    }
  }
}))


const useStyles = makeStyles(theme => ({
  root : {
    marginTop: '2rem',
    '& .MuiDivider-root': {
      flexGrow: 1
    }
  },
  wrapper : {
    [theme.breakpoints.up('lg')] : {
      margin : '0 0 4rem 3rem'
    },
    display: 'flex',
    flexDirection: 'column',
    padding: '2rem',
    borderRadius: '16px',
    boxShadow: '-24px 24px 72px 8px rgb(145 158 171 / 24%)',
    '& a': {
      color: theme.primaryColorOrange,
      fontWeight: 400
      
    }
  },
  image : {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  title : {
    marginBottom: '.5rem',
    fontWeight: 500
  },
  field : {
    marginBottom: '1.5rem',
    borderRadius: '1.6rem',
    '& *': {
      borderRadius: '8px'
    },
    '& label.Mui-focused':{
      color: theme.primaryColorOrange
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        border: `1px solid ${theme.primaryColorOrange}`
      }
    }
  },
  btn: {
    padding: '1rem 0',
    color: '#fff',
    borderRadius: '8px',
    marginBottom: '1rem',
    '&:hover' : {
      background: theme.secondaryColorDark
    }
  },
  btnBack: {
    background: theme.secondaryColorDark,
    padding: '1rem 0',
    color: '#fff',
    borderRadius: '8px',
    marginBottom: '1rem',
    '&:hover' : {
      background: theme.secondaryColorDark
    }
  },
  btnGoogle: {
    padding: '1rem 0',
    background: grey[300],
    borderRadius: '8px'
  },
  flex : {
    marginBottom: '2rem',
    display: 'flex',
    gap: '1rem',
    justifyContent: 'center',
    alignItems: 'center'
  },
  fieldIcon: {
    color: grey[400],
    cursor: 'pointer',
  },
  toolbarHeight: theme.mixins.toolbar,
  upload:{
    padding: '1rem',
    textAlign: 'center',
    border: `1px solid ${grey[300]}`,
    borderRadius: '8px',
    marginBottom: '1.5rem',
    cursor: 'pointer',
    '&:hover' : {
      background: grey[100]
    }
  }

}))



const Register = (props) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const[toggle, setToggle] = useState(false)
  const [step, setStep] = useState(0)
  const [check, setCheck] = useState(false)
  const [load, setLoad] = useState(false)


  // initialState //
  const initialState = {
    fname: '', 
    lname: '', 
    other: '',
    email: '',
    phone: '',
    dob: '',
    password: '',
    address: '',
    digital_address: '',
    sex: '',
    occupation: '',
    company: '',
    companyAddress: '',
    bank: '',
    accNumber: '',
    bankBranch: '',
    monthlySalary: '',
    loan: '',
    security: '',
    nationalID: '',
    id_issue_date: '',
    id_expiry_date: '',
    payslip: '',
    sdo: '',
    ghCard: ''
  }
  //reducer Function //
  const reducerFn = (state, action) => {
    switch (action.type) {
      case "FIRST_NAME":
        return {...state, fname: action.payload.toUpperCase()}
      case "LAST_NAME":
        return {...state, lname: action.payload.toUpperCase()}
      case "OTHER_NAME":
          return {...state, other: action.payload.toUpperCase()}
      case "EMAIL":
        return {...state, email: action.payload.toUpperCase()}
      case "PHONE":
        return {...state, phone: action.payload}
      case "DOB":
        return {...state, dob: action.payload}
      case "PASSWORD":
        return {...state, password: action.payload}
      case "ADDRESS":
        return {...state, address: action.payload.toUpperCase()}
      case "SEX":
        return {...state, sex: action.payload.toUpperCase()}
      case "DIGITAL_ADDRESS":
        return {...state, digital_address: action.payload.toUpperCase()}
      case "OCCUPATION":
          return {...state, occupation: action.payload.toUpperCase()}
      case "COMPANY":
          return {...state, company: action.payload.toUpperCase()}
      case "COMPANY_ADDRESS":
        return {...state, companyAddress: action.payload.toUpperCase()}
      case "MONTHLY_SALARY":
        return {...state, monthlySalary: action.payload}
      case "LOAN":
        return {...state, loan: action.payload}
      case "NATIONAL_ID":
        return {...state, nationalID: action.payload.toUpperCase()}
      case "ISSUE_DATE":
        return {...state, id_issue_date: action.payload}
      case "EXPIRY_DATE":
        return {...state, id_expiry_date: action.payload}
      case "RESET":
        return initialState
      default:
        return state;
    }
  }
  const [formInput, dispatch] = useReducer(reducerFn, initialState)


  const stepBack = () => {
    setStep(prev => {
      return prev - 1
    })
  }

  const onFormSubmit = async(e) => {
    e.preventDefault();
    if(step === 0) {
      // do checks //
      if(formInput.fname === '' || formInput.lname === '') {
        props.errorModal('Please provide firstname and lastname')
        return
      }
      if(formInput.email === '' || !formInput.email.includes('@') || !formInput.email.includes('.COM') ) {
        props.errorModal('Please provide valid email address')
        return
      }
      if(formInput.phone === '' || formInput.phone.length < 9 ) {
        props.errorModal('Please provide a valid phone number')
        return
      }
      if(formInput.dob === '') {
        props.errorModal('Please provide a valid date of birth')
        return
      }
      if(formInput.password === '' || formInput.password.length < 7) {
        props.errorModal('Invalid password must be more than 6 characters')
        return
      }
      // move to next step
      setStep(1)
      window.scrollTo({top: 0, behavior: 'smooth'})
    }
    if(step === 1) {
      // do checks //
      if(formInput.address === '' || formInput.occupation === '' || formInput.company === '' || formInput.companyAddress === '' || formInput.monthlySalary === '' || formInput.nationalID === '') {
        props.errorModal('Please fill all required(*) fields')
        return
      }

      // “GHA-000000000-0”
      if(formInput.nationalID.length > 15 || !formInput.nationalID.includes('-')){
        props.errorModal('Invalid Ghana Card Number')
        return
      }

      setLoad(true)

      // create formData
      const formData = new FormData();
      formData.append('fname', formInput.fname.trim())
      formData.append('lname', formInput.lname.trim())
      formData.append('other', formInput.other.trim())
      formData.append('email', formInput.email.trim())
      formData.append('phone', formInput.phone.trim())
      formData.append('dob', formInput.dob)
      formData.append('password', formInput.password.trim())
      formData.append('address', formInput.address.trim())
      formData.append('occupation', formInput.occupation.trim())
      formData.append('company', formInput.company.trim())
      formData.append('companyAddress', formInput.companyAddress.trim())
      formData.append('monthlySalary', formInput.monthlySalary.trim())
      formData.append('IDNumber', formInput.nationalID.trim() )

      // call action creator
      const status = await props.registerUser(formInput)
      if(status === 'success'){
        dispatch({type: "RESET"}) 
        setLoad(false)
        navigate('/u/account/dashboard')
      }else{
        setLoad(false)
      }

    }
}

  return (
    <>
      {/* MODAL  */}
      { props.modal && <Modal status={props.modal.status} />}
      
      <div className={classes.toolbarHeight}></div>
      <Container className={classes.root}>

        <Grid container spacing={5}>
          <Hidden smDown>
            <Grid item xs={12} sm={6} md={6} lg={6} className={`${classes.image} animate__animated animate__fadeIn`}>
              <img style={{width: '90%'}} src={LoginImage} alt='login-icon' />
            </Grid>
          </Hidden>

          <Grid item xs={12} sm={12} md={6} lg={6} style={{display: 'flex', justifyContent: 'center'}} className={`animate__animated animate__zoomIn`}>
            <div className={`${classes.wrapper}`}>
              <Typography className={classes.title} variant='h4'>Register</Typography>
              <Typography style={{marginBottom: '2.5rem'}} paragraph color='textSecondary'>Already have an account? <Link href='/auth/login'>Login</Link></Typography>

              { step === 0 && 
              <form >
                  <InputField className={classes.field} variant='outlined' value={formInput.fname} onChange={(e)=> dispatch({type:"FIRST_NAME", payload: e.target.value})} required label='First Name' helperText='' fullWidth />
                  <InputField className={classes.field} variant='outlined' value={formInput.lname} onChange={(e)=> dispatch({type:"LAST_NAME", payload: e.target.value})} required label='Last Name' helperText='' fullWidth />
                  <InputField className={classes.field} variant='outlined' value={formInput.other} onChange={(e)=> dispatch({type:"OTHER_NAME", payload: e.target.value})} label='Middle Name' helperText='' fullWidth />
                  <InputField className={classes.field} variant='outlined' value={formInput.email} onChange={(e)=> dispatch({type:"EMAIL", payload: e.target.value})} required label='Email' helperText='' fullWidth />
                  <InputField className={classes.field} variant='outlined' value={formInput.phone} placeholder='024*******'
                    onChange={(e)=> dispatch({type:"PHONE", payload: e.target.value})} required inputProps={{ maxLength: '10' }} label='Phone' fullWidth />
                   <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        views={['year','month', 'day']}
                        label="Date of Birth"
                        value={formInput.dob}
                        onChange={(newValue)=> dispatch({type:"DOB", payload: newValue})}
                        renderInput={(params) => {
                          return <InputField placeholder='dd/mm/yy' required sx={{border: 'none'}} fullWidth className={classes.field} {...params} />
                        }}
                      />
                   </LocalizationProvider>
                   <InputField select className={classes.field} variant='outlined' defaultValue={''} value={formInput.sex} onChange={(e)=> dispatch({type:"SEX", payload: e.target.value})} required label='Gender' fullWidth >
                        <MenuItem value='MALE' >Male</MenuItem>
                        <MenuItem value='FEMALE' >Female</MenuItem>
                   </InputField>
                  <InputField className={classes.field} variant='outlined' value={formInput.password} onChange={(e)=> dispatch({type:"PASSWORD", payload: e.target.value})} required label='Password' 
                  helperText='' fullWidth type={ toggle ? 'text' : 'password' }
                  InputProps={{ endAdornment: <InputAdornment position='end' onClick={()=>setToggle(!toggle)} > { toggle ? <Visibility className={classes.fieldIcon} /> : <VisibilityOff className={classes.fieldIcon}/> } </InputAdornment>}}
                  />
                
                  <Button sx={{
                        padding: '1rem 0',
                        color: '#fff',
                        borderRadius: '8px',
                        marginBottom: '1rem',
                        '&:hover' : {
                          background: theme => theme.secondaryColorDark
                        }
                  }} color='primary' variant='contained' disableElevation onClick={(e)=>onFormSubmit(e)} fullWidth>Continue</Button>
              </form>
              }

              { step === 1 &&
              <form >
                  <InputField className={classes.field} autoComplete='off' value={formInput.address} onChange={(e)=> dispatch({type:"ADDRESS", payload: e.target.value})} required  variant='outlined' label='Res. Address' helperText='' fullWidth />
                  <InputField className={classes.field} autoComplete='off' value={formInput.digital_address} onChange={(e)=> dispatch({type:"DIGITAL_ADDRESS", payload: e.target.value})} required  variant='outlined' label='Digital Address' helperText='' fullWidth />
                  <InputField className={classes.field} variant='outlined' value={formInput.occupation} onChange={(e)=> dispatch({type:"OCCUPATION", payload: e.target.value})} required label='Occupation' helperText='' fullWidth />
                  <InputField className={classes.field} variant='outlined' value={formInput.company} onChange={(e)=> dispatch({type:"COMPANY", payload: e.target.value})} required label='Name of Company' helperText='' fullWidth />
                  <InputField className={classes.field} variant='outlined' value={formInput.companyAddress} onChange={(e)=> dispatch({type:"COMPANY_ADDRESS", payload: e.target.value})} required label="Company's Address" helperText='' fullWidth />
                  
                  <InputField className={classes.field} variant='outlined' value={formInput.monthlySalary} InputProps={{startAdornment: <InputAdornment position='start'>GH¢</InputAdornment>}}
                    onChange={(e)=> dispatch({type:"MONTHLY_SALARY", payload: e.target.value})} required type='number' inputProps={{min: 0}} label='Monthly Salary' helperText='' fullWidth />
                  
                  <InputField className={classes.field} required variant='outlined' label='Ghana Card Number' inputProps={{maxLength: 15}} value={formInput.nationalID} onChange={(e)=> dispatch({type:"NATIONAL_ID", payload: e.target.value})} fullWidth placeholder='XXX-XXXXXXXXX-X' />
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          views={['year','month', 'day']}
                          label="ID Issue Date"
                          value={formInput.id_issue_date}
                          onChange={(newValue)=> dispatch({type:"ISSUE_DATE", payload: newValue})}
                          renderInput={(params) => {
                            return <InputField required sx={{border: 'none'}} fullWidth className={classes.field} {...params} />
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          views={['year','month', 'day']}
                          label="ID Expiry Date"
                          value={formInput.id_expiry_date}
                          onChange={(newValue)=> dispatch({type:"EXPIRY_DATE", payload: newValue})}
                          renderInput={(params) => {
                            return <InputField required sx={{border: 'none'}} fullWidth className={classes.field} {...params} />
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                  {
                    step === 1 && 
                    <FormControlLabel style={{textAlign: 'center'}} control={<Checkbox checked={check} onClick={()=> setCheck(!check)} />} label={<Typography  variant='body2' color='textSecondary' >I agree to <Link href='/policy' target={'_blank'}>Terms of Service and Privacy Policy</Link>.</Typography>} />
                  }
                 
                  <Box sx={{display:'flex', gap:'1rem', mt: '1rem'}}>
                    <Button sx={{
                      background: theme => theme.secondaryColorDark,
                      padding: '1rem 0',
                      color: '#fff',
                      borderRadius: '8px',
                      '&:hover' : {
                        background: theme => theme.secondaryColorDark
                      }
                    }} disableElevation onClick={stepBack} fullWidth>Back</Button>
                    <LoadingButton loading={load} sx={{
                      padding: '.8rem 0',
                        color: '#fff',
                        borderRadius: '8px'
                    }} color='primary' variant='contained' disabled={check ? false : true } disableElevation onClick={(e)=>onFormSubmit(e)} fullWidth>Register</LoadingButton>
                  </Box>
              </form>

              }

              
                
            </div>

          </Grid>
        </Grid>
      </Container>
    </>

  ) 
};

const mapStateToProps = (state) => {
  return state
}

export default connect(mapStateToProps, {registerUser, errorModal})(Register);
