import './App.css'
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { ThemeProvider } from "@mui/material/styles";
import { Routes, Route, useLocation, Navigate } from "react-router-dom"
import { theme } from './theme.js'
import Home from "./pages/Home.js";
import About from "./pages/About.js";
import Contact from "./pages/Contact.js";
import Login from "./pages/Login.js";
import Register from "./pages/Register.js";
import Account from './pages/user/Account';
import {connect} from 'react-redux'
import Navbar from './components/Navbar';
import { Box } from '@mui/material';
import Footer from './components/Footer';
import { authRequest } from './actions/actions';
import { useEffect } from 'react';
import Verify from './pages/Verify';
import Activate from './pages/Activate';
import Loader from './components/Loader';
import Policy from './pages/Policy';
import GeneralPolicy from './pages/GeneralPolicy';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Errorpage from './pages/ErrorPage';
import { useIsOnline } from 'react-use-is-online';
import Offline from './pages/Offline';


function App({loader, currentUser, authRequest  }) {
  const { isOnline, isOffline } = useIsOnline()
  const url = useLocation()
  const path = url.pathname 

  // Fetch All Products into store
  useEffect(() => {
    authRequest()
}, [ authRequest ])

const renderResetPassword = () => {
  const token = url.search.split('=')[1];
  if(token === undefined){
    return <Navigate to='/' />
  }else {
    return <ResetPassword />
  }
}

  return (
    
    <ThemeProvider theme={theme}>
    {
      !loader && isOnline ? 
        <>

          <Box sx={{background: '#3f5176'}}>
          { path.startsWith('/u') ? null : <Navbar bg={ path.startsWith('/auth') ? '#fff' : theme.secondaryColorDark}/> }
          </Box>

          <Routes className="App">
          
            <Route exact path='/' element={<Home />}  />
            <Route exact path='/loader' element={<Loader />}  />
            <Route path='/about' element={<About />}  />
            <Route path='/contact' element={<Contact />}  />
            <Route path='/cardpolicy' element={<Policy />}  />
            <Route path='/policy' element={<GeneralPolicy />}  />

            {/* USER AUTHENTICATION */}
            <Route path='/auth/login' element={ currentUser ? <Navigate to={'/'} /> : <Login /> }  />
            <Route path='/auth/register' element={ currentUser ? <Navigate to={'/u/account/dashboard'} /> : <Register />}  />
            <Route path='/auth/verify' element={ currentUser && currentUser.verificationCode ? <Verify /> : <Navigate to={'/'} />  }  />
            <Route path='/auth/activate' element={ <Activate /> }  />
            <Route path='/auth/forgotpassword' element={ currentUser ? <Navigate to={'/'} /> : <ForgotPassword /> }  />
            <Route path='/auth/resetpassword' element={ renderResetPassword() }  />

            {/* USER DASHBOARD */}
            <Route path='/u/account/dashboard' element={<Account />}  />
            <Route path='/u/account/payment' element={<Account />}  />
            <Route path='/u/account/transactions' element={<Account />}  />
            <Route path='/u/account/payment/:id/details' element={<Account />}  />
            <Route path='/u/account/settings' element={<Account />}  />
            <Route path='/u/account/documents' element={<Account />}  />
            <Route path='/u/account/profile' element={<Account />}  />
            <Route path='/u/account/notifications' element={<Account />}  />
            <Route path='/u/account/downloads' element={<Account />}  />
            <Route path='/u/account/support' element={<Account />}  />

            <Route path='*' element={<Errorpage />} />


          </Routes>
          { path.startsWith('/u/account') ? null : <Footer /> }

        </> 
        : 
        !loader && isOffline ? <Offline /> 
        :
        <Loader />
    }
    </ThemeProvider>
  );
}


const mapStateToProps = (state) => {
  // console.log(state)
  return state
}

export default connect(mapStateToProps,  { authRequest })(App);
