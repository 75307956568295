import { alpha, Avatar, Box, Button, Card, CardContent, Chip, Grid ,TextField, IconButton, LinearProgress,Step,StepLabel,Stepper, Dialog, DialogActions, DialogContent, DialogTitle ,Typography, Hidden, styled, Divider, ButtonGroup, InputAdornment } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, {useRef, useState} from 'react';
import PageHeader from './dashboard/PageHeader';
import { AccountBalanceWallet, ArrowRightAlt, Close, Email, LocalMall, Visibility, VisibilityOff } from '@mui/icons-material';
import { errorModal, getUserCard, resendEmailVerification, activateAccountEmail, activateAccountSMS, resendSMSCode, successModal } from '../../actions/actions';
import { connect, useDispatch } from 'react-redux'
import MasterCard from '../../assets/images/mastercard.svg'
import Modal from '../../components/Modal';
import Tour from 'reactour'
import Slider from '../../assets/images/slider1.jpg'
import Banking from '../../assets/images/money-exchange.png'
import { useNavigate } from 'react-router-dom';
import { LoadingButton} from '@mui/lab';
// import { grey } from '@mui/material/colors';

const StyledCard = styled(Card)(({theme}) => ({
  background: '#18569a',
  padding: '1rem',
  borderRadius: '15px',
  height: '85%'
}))


const useStyles = makeStyles(theme => ({
    slider : {
      display: 'flex',
      height: '15rem',
      padding: '3rem',
      borderRadius: '15px',
      backgroundImage: `linear-gradient(45deg, rgba(0,0,0, 80%), transparent ), url(${Slider})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      marginBottom: '2rem'
  },
  slideLeft: {
      display: 'flex',
      flex: '1', 
      flexDirection: 'column', 
      justifyContent: 'flex-end', 
      alignItems: 'flex-start',
      '& h4, p': {
          color: '#fff'
      }
  },
  bannerImage : {
    width: '100%'
  },
  bannerTitle: {
    color: '#fff',
    fontWeight: 600,
    fontSize:  '1.8rem',
    marginTop: '1rem'
  },
  title : {
    fontWeight: 700,
    marginTop: '1rem',
    color: '#fff'
  },

  card : {
    
  },
  cardText: {
    color: alpha('rgb(255,255,255)', 0.5),
    '& span': {
      fontWeight:600,
      color: '#fff'
    }
  },
  cardIcon : {
    background: theme.primaryColorOrange,
    marginBottom: '2rem'
  },
  stages : {
    padding: 0,
    background: 'none'
  },
  wrap : {
    width: '60%'
  },
  stage : {
    padding: 0,
    background: 'none',
    marginBottom: '.8rem',
    color: '#fff'
  },
  helpCenter: {

  }
}))

const Overview = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { user, getUserCard, activateAccountEmail, activateAccountSMS, resendSMSCode } = props
  const [value, setValue] = useState('1')
  const [show, setShow] = useState(false)
  const [verify, setVerify] = useState(false)
  const [load, setLoad] = useState(false)
  const [load2, setLoad2] = useState(false)
  const [secure, setSecure] = React.useState(false)
  const [security, setSecurity] = React.useState({ans: '', err: ''})
  const [open, setOpen] = useState(false)
  const [code, setCode] = useState('')
  const accountRef = useRef()

  React.useEffect(() => {
    if(user.status === 'Approved'){
      getUserCard();
    }
  },[getUserCard, user])

  const showCardNumber = () => {
    if(show){
      setShow(false)
    }else{
      setSecure(true)
    }
  }
  const closeDialog = () => {
    setSecure(false)
    setSecurity({ans: '', err: ''})
  }

  const securityCheck = (answer) => {
    if(answer.trim().toUpperCase() === props.currentUser.security){
        closeDialog()
        setShow(true)
    }else{
        setSecurity((prev) => {
            return {...prev, err: 'Wrong answer'}
        })
    }
  }

  const renderCardNumber = () => {
    if(props.card.number && show === false){
      return props.card.number.replace(/\d(?=\d{4})/g, "*") 
    }
    if(props.card.number && show === true){
      return props.card.number
    }
    return '****-****-****-****'
  }

  const resendEmail = async(val) => {
    setLoad(true)
    if(val === 'email') await props.resendEmailVerification()
    if(val === 'sms') await props.resendSMSCode()
    setLoad(false)
  }

  const activateUserAccount = async(type) => {
    if(code === '' ){
      dispatch(errorModal('Invalid. Provide activation code'))
      return
    }if(code.length < 6 ){
      dispatch(errorModal('Invalid. Activation code must be 6 characters'))
      return
    }
    setLoad(true)
    if(type === 'email'){
      const status = await activateAccountEmail(code)
      if(status === 'success'){
        setCode('')
        dispatch(successModal('Thank you. Your WePay account is activated'))
        setVerify(false)
        setLoad(false)
        window.location.reload()
      }else{
        setLoad(false)
        dispatch(errorModal(status.message))
      }
    }
    if(type === 'sms'){
      const status = await activateAccountSMS(code)
      if(status === 'success'){
        setCode('')
        dispatch(successModal('Thank you. Your WePay account is activated'))
        setVerify(false)
        setLoad(false)
        window.location.reload()
      }else{
        setLoad(false)
        dispatch(errorModal(status.message))
      }

    }

  }

  const handleChange = (newValue) => {
    setValue(newValue)
  };

  const sendSMSCode = async() => {
    setLoad2(true)
    await resendSMSCode()
    setLoad2(false)
    window.location.reload()
  }


  const renderPage = () => {
    if(props.user.emailStatus === 0){
      return <div>
              <Typography paragraph variant='body1' className={classes.cardText} style={{ marginTop: '.4rem'}}> 
                    Please check your email ({props.user.email}) to activate your account.
                  </Typography>
              <Box>
                <LinearProgress style={{height: '.5rem', borderRadius:'.5rem'}} variant='determinate' value={props.user.completion} />
                <Typography variant='body2' color='primary' style={{marginTop: '.5rem'}}>{props.user.completion}% Complete.</Typography>
              </Box>
                  
              <Button size='small' variant='contained' style={{marginTop: '.8rem'}} onClick={()=>setVerify(true)} >Verify Email</Button>
            </div>
    }
    if(props.user.emailStatus !== 0 && props.user.bankStatus === 0){
      return <>
                <Typography paragraph variant='body1' className={classes.cardText} style={{ marginTop: '.4rem'}}> 
                Please update your bank details to get approval for your WePay Card.
                    </Typography>
                <Box>
                  <LinearProgress style={{height: '.5rem', borderRadius:'.5rem'}} variant='determinate' value={props.user.completion} />
                  <Typography variant='body2' color='primary' style={{marginTop: '.5rem'}}>{props.user.completion}% Complete.</Typography>
                </Box>
                    
                <Button size='small' variant='contained' style={{marginTop: '.8rem'}} onClick={() => navigate('/u/account/documents')} >Update bank Details</Button>
            </>
      }
    if(props.user.emailStatus !== 0 && props.user.bankStatus !== 0 && (props.user.payStatus === 0 || props.user.ghcardStatus === 0)){
      return <>
              <Typography paragraph variant='body1' className={classes.cardText} style={{ marginTop: '.4rem'}}> 
              Please upload your Ghana Card and Payslip to get approval for your WePay Card.
                  </Typography>
              <Box>
                <LinearProgress style={{height: '.5rem', borderRadius:'.5rem'}} variant='determinate' value={props.user.completion} />
                <Typography variant='body2' color='primary' style={{marginTop: '.5rem'}}>{props.user.completion}% Complete.</Typography>
              </Box>
                  
              <Button size='small' variant='contained' style={{marginTop: '.8rem'}} onClick={() => navigate('/u/account/documents')} >Upload Documents</Button>
        </>
    }
    if(props.user.emailStatus !== 0 && props.user.bankStatus !== 0 && props.user.photoStatus === 0 ){
      return <>
              <Typography paragraph variant='body1' className={classes.cardText} style={{ marginTop: '.4rem'}}> 
              Please upload profile photo to get approval for your WePay Card.
                  </Typography>
              <Box>
                <LinearProgress style={{height: '.5rem', borderRadius:'.5rem'}} variant='determinate' value={props.user.completion} />
                <Typography variant='body2' color='primary' style={{marginTop: '.5rem'}}>{props.user.completion}% Complete.</Typography>
              </Box>
                  
              <Button size='small' variant='contained' style={{marginTop: '.8rem'}} onClick={() => navigate('/u/account/profile')} >Upload Photo</Button>
        </>
    }
    if(props.user.status === 'Approved'){
      return <>
            <Typography variant='body1' className={classes.cardText} style={{ marginTop: '.4rem', marginBottom: '2rem'}}> 
              Congratulations!! Your account has been approved successfully. Your account bears a credit limit of up to GH¢ {props.user.loan[0].limit.toLocaleString()}.
            </Typography>
            <Box>
              <Stepper alternativeLabel activeStep={ props.user.status === 'Approved' && 3  } className={classes.stage}>
                <Step > <StepLabel><Typography variant='body2' sx={{color: '#fff'}}>Documents</Typography></StepLabel> </Step>
                <Step> <StepLabel><Typography variant='body2' sx={{color: '#fff'}}>Verified</Typography></StepLabel> </Step>
                <Step> <StepLabel><Typography variant='body2' sx={{color: '#fff'}}>Approved</Typography></StepLabel> </Step>
              </Stepper>
             </Box>
      </>

    }
    if(props.user.status === 'Verified'){
      return <>
            <Typography variant='body1' className={classes.cardText} style={{ marginTop: '.4rem', marginBottom: '2rem'}}> 
              Congratulations!! Your account has been verified successfully. You have been approved a credit limit of GH¢ {props.user.loan[0].limit.toLocaleString()}.
            </Typography>
            <Box>
              <Stepper alternativeLabel activeStep={ props.user.status === 'Verified' && 2 } className={classes.stage}>
                <Step > <StepLabel><Typography variant='body2' sx={{color: '#fff'}}>Documents</Typography></StepLabel> </Step>
                <Step> <StepLabel><Typography variant='body2' sx={{color: '#fff'}}>Verified</Typography></StepLabel> </Step>
                <Step> <StepLabel><Typography variant='body2' sx={{color: '#fff'}}>Approved</Typography></StepLabel> </Step>
              </Stepper>
             </Box>
      </>

    }
    
      return <>
            <Typography variant='body1' className={classes.cardText} style={{ marginTop: '.4rem', marginBottom: '2rem'}}> 
              Congratulations!! Your account is currently under verification. We will prompt you when this process is done.
            </Typography>
            <Box>
                <Stepper alternativeLabel activeStep={ props.user.status === 'Pending' && 1 } className={classes.stage}>
                  <Step > <StepLabel> <Typography variant='body2' sx={{color: '#fff'}}>Documents</Typography> </StepLabel> </Step>
                  <Step> <StepLabel> <Typography variant='body2' sx={{color: '#fff'}}>Verified</Typography> </StepLabel> </Step>
                  <Step> <StepLabel> <Typography variant='body2' sx={{color: '#fff'}}>Approved</Typography> </StepLabel> </Step>
                </Stepper>
             </Box>
      </>
        
    
  }

  const steps = [
    {
      selector: `#activation`,
      content: () => {
        return <>
        <Typography style={{fontWeight: 500, fontSize: '1.2rem'}} gutterBottom>Activate your account</Typography>
          <Typography color='textSecondary'>
            Please check your email/inbox ({props.user.email}) to activate your account.
          </Typography>
        </>
        }
    },
    {
      selector: `#account`,
      content: () => {
        return <>
        <Typography style={{fontWeight: 500, fontSize: '1.2rem'}} gutterBottom>Upload Photo</Typography>
          <Typography color='textSecondary' gutterBottom>
            Upload your passport size photo to complete your account status, get verified and approved for a loan.
          </Typography>
        </>
        }
    },
    {
      selector: `#documents`,
      content: () => {
        return <>
        <Typography style={{fontWeight: 500, fontSize: '1.2rem'}} gutterBottom>Upload Documents</Typography>
          <Typography color='textSecondary' gutterBottom>
            Upload your documents in the same account section to complete your account status, get verified and approved for a loan.
          </Typography>
        
        </>
        }
    },
    {
      selector: `#getLoan`,
      content: () => {
        return <>
        <Typography style={{fontWeight: 500, fontSize: '1.2rem'}} gutterBottom>Request For A Loan</Typography>
          <Typography color='textSecondary' gutterBottom>
            Become eligible after account verification to apply for a loan. Your credit limit will also be provided upon verification
          </Typography>
        </>
        }
    },
    {
      selector: `#help`,
      content: () => {
        return <>
        <Typography style={{fontWeight: 500, fontSize: '1.2rem'}} gutterBottom>Need Help?</Typography>
          <Typography color='textSecondary' gutterBottom>
            I'm not far. Find me here at the bottom corner of your dashboard when you need a guide and I will be there to help.
          </Typography>
          <Button variant='contained' color='secondary' onClick={()=>setOpen(false)}>Continue</Button>
        </>
        }
    }
  ]

  const tourGuide = () => {
    setOpen(true)
  }

  return (    
    <Box sx={{
      '& .MuiChip-colorPrimary': {
        background: theme => theme.primaryColorOrange
      },
      '& .MuiLinearProgress-colorPrimary': {
        background: '#fff'
      },
      '& .MuiLinearProgress-barColorPrimary': {
        background: theme => theme.primaryColorOrange
      },
      '& .MuiStepLabel-label' : {
        color: alpha('rgb(255,255,255)', 0.5),
      },
      '& .MuiStepLabel-active' : {
        color: theme => theme.primaryColorOrange
      },
    }}>
        <PageHeader title={'Overview'} link2={'dashboard'} user={props.user} tourGuide={tourGuide}/>
        {props.modal && <Modal status={props.modal.status} />}

        {/* TOUR GUIDE */}
        {/* <IconButton id='help' className={classes.helpCenter} style={{background:'#24324d',zIndex:99, position: 'fixed', bottom: '5%', right: '2.5%', opacity: 0}} onClick={()=>setOpen(true)}>
          <Help style={{fontSize: '2.5rem', color:'#fff'}}  />
        </IconButton> */}
        <Tour steps={steps} showCloseButton={false} isOpen={open} closeWithMask={false} onRequestClose={()=>setOpen(false)}/>

        <Grid container spacing={3} >
              <Hidden mdDown>
                  <Grid item lg={8} sm={12}>
                      <Box className={classes.slider} >
                        <span className={classes.slideLeft}>
                            <Chip size='small' color='primary' sx={{mb: 2}} label={props.user?.status} />
                            <Typography variant='h4' gutterBottom >Hello {props.user?.name?.firstname}</Typography>
                            <Typography color='GrayText' gutterBottom>
                            Let's get started. Complete the following steps to get your WePay Card. Also complete your profile to raise your credit limit.
                            </Typography>
                            {/* <Button variant='contained' color='primary' disableElevation style={{textTransform: 'none', marginTop: '1rem'}}>Get Started</Button> */}
                        </span>
                        <span style={{flex: '1'}}>

                        </span>
                      </Box>
                      
                  </Grid>
              </Hidden>

                <Grid item sm={12} xs={12} lg={4} sx={{height: '100%', mb: '2rem'}}>
                    <Card elevation={0} sx={{borderRadius: '15px', border: theme => `1px solid ${theme.palette.primary.main}`}}>
                      <Box sx={{padding: '1rem 1.5rem', display: 'flex', justifyContent: 'space-between'}}>
                          <Typography variant='h6'>Transactions</Typography>
                          <Button sx={{textTransform: 'none'}} endIcon={<ArrowRightAlt />}>View all</Button>
                      </Box>
                      <Divider />
                      <CardContent sx={{height: '13.8rem', overflowY: 'scroll', padding: '1.5rem'}}>
                            <img src={Banking} width={'25%'} style={{margin: '0 auto', marginTop: '1rem', display: 'block'}} alt='transactions' />
                            <Typography variant='body2' my={2} textAlign={'center'} color={'GrayText'}>You have no transactions</Typography>
                            {/* {
                              [1,2,3,4,5].map((el,index) => {
                                return (
                                  <Card key={index} elevation={0} sx={{border: `1px solid ${grey[200]}`, borderRadius: '10px', marginBottom: '10px'}}>
                                    <CardContent sx={{display: 'flex', gap: '1rem', justifyContent: 'flex-start'}}>
                                      <img src={MasterCard} alt='mastercard' />
                                      <Stack>
                                        <Typography>**** 5678</Typography>
                                        <Typography variant='body2' sx={{fontSize: '.8rem'}} color={'GrayText'}>5th Feb, 2023</Typography>
                                      </Stack>
                                      <Typography sx={{fontWeight: 600, ml: 'auto'}}>GHc 500</Typography>
                                    </CardContent>
                                  </Card>
                                )
                              })
                            } */}
                      </CardContent>
                    </Card>
                </Grid>

              
        </Grid>

        <Grid container spacing={3}>
            <Grid item lg={4} sm={12} xs={12}>
                <StyledCard className={classes.card} elevation={0} id='activation'>
                    <CardContent>
                      <Typography variant='h6' ref={accountRef} style={{color:'#fff'}}>Account Status</Typography>
                      {renderPage()}
                    </CardContent>
                  </StyledCard>
            </Grid>

            <Grid item lg={4} md={6} sm={12} xs={12}>
              <StyledCard className={classes.card} elevation={0}>
                <CardContent>
                    <Avatar sx={{bgcolor: 'primary.main', marginBottom: '2rem'}}       variant='rounded'><AccountBalanceWallet /> </Avatar>
                    <Typography variant='h4' gutterBottom className={classes.title}>GH¢ {props.card.amount ? props.card.amount.toLocaleString() : '0.00'}</Typography>
                    <Typography variant='h6' className={classes.cardText}>
                    {renderCardNumber()}
                    { props.card.number && <IconButton onClick={showCardNumber}> { show ? <Visibility fontSize='small' style={{color: 'lightgrey'}} /> : <VisibilityOff fontSize='small' style={{color: 'lightgrey'}}/> } </IconButton> }
                    </Typography>
                    { props.card.number ? 
                        <Grid container>
                          <Grid item sm={8}>
                            <Typography variant='h6' className={classes.cardText}>{props.user.fullname.toUpperCase()}</Typography>
                            <Typography variant='body2' className={classes.cardText}>Expiry: {`${props.card.expiry.month}/${props.card.expiry.year}`}</Typography>
                          </Grid>
                          <Grid item sm={4} style={{ display:'flex', flexDirection:'row-reverse', justifyItems: 'flex-end'}}>
                          <img src={MasterCard} alt='visa' width={'40%'} />
                          </Grid>
                        </Grid> 
                        : <Typography paragraph variant='body2' className={classes.cardText}>Available Card Balance</Typography> 
                      }

                </CardContent>
              </StyledCard>
            </Grid>

          <Grid item lg={4} md={6} sm={12} xs={12}>
          <StyledCard className={classes.card} elevation={0}>
              <CardContent style={{position: 'relative'}}>
              <Chip label={'Coming soon'} color='primary' style={{position: 'absolute', top: '10%', right: '5%'}} />
              <Avatar sx={{bgcolor: 'primary.main', marginBottom: '2rem'}} variant='rounded'><LocalMall /> </Avatar>
              <Typography variant='h4' gutterBottom className={classes.title}>{`GH¢ ${0}.00`}</Typography>
              <Typography variant='h6' className={classes.cardText}>Savings / Susu</Typography>
              <Typography paragraph variant='body2' className={classes.cardText}>Contribution</Typography>

              </CardContent>
            </StyledCard>
          </Grid>

        
        </Grid>

      {/* SECURITY CHECK */}

      <Dialog open={secure} >
          <DialogTitle>Security Question</DialogTitle>
            <DialogContent style={{width: '30rem'}}>
                <TextField variant='outlined' error={security.err === '' ? false : true} value={security.ans} onChange={(e)=>setSecurity({ans: e.target.value, err: '' })} fullWidth label="Mother's Maiden Name" helperText={security.err} />
            </DialogContent>
          <DialogActions>
              <Button onClick={()=>securityCheck(security.ans)}>Answer</Button>
              <Button onClick={closeDialog}>Cancel</Button>
          </DialogActions>
      </Dialog>

      {/* VERIFY EMAIL */}
      <Dialog open={verify} maxWidth='xs' >
        <DialogContent sx={{padding: '2rem', position: 'relative'}}>
          <IconButton sx={{position: 'absolute', top: '3%', right: '5%'}} onClick={()=> {setVerify(false); setCode('')}} ><Close fontSize='small' /></IconButton>
          <Box mb={2} sx={{textAlign: 'center'}}>
            <Avatar sx={{width: '5rem', height: '5rem', margin: '.5rem auto'}}><Email sx={{fontSize: '3rem'}}/></Avatar> 
            <Typography variant='h5'>Verify Your Account</Typography>
          </Box>
            <ButtonGroup disableElevation variant='outlined' fullWidth color='secondary' sx={{mb: '1rem'}}>
              <Button variant={ value === '1' ? 'contained' : 'outlined'} color='secondary' onClick={()=>handleChange('1')}>Email</Button>
              <Button variant={ value === '2' ? 'contained' : 'outlined'} color='secondary' onClick={()=>handleChange('2')}>SMS</Button>
            </ButtonGroup>

            <Box>
            {
              value === '1' ?
              <Typography variant='body1' mb={2} color={'GrayText'}>Provide the 6-character activation code sent to your inbox ({props.user.email}) to verify your WePay account.</Typography>
              :
              <Typography variant='body1' mb={2} color={'GrayText'}>Provide the 6-character activation code sent to your phone ({props.user.phone}) to verify your WePay account.</Typography>
            }

              <TextField value={code} onChange={(e)=> setCode(e.target.value)} sx={{mb: 2}} variant='outlined' fullWidth label={value === '1' ? 'Email Code' : 'SMS Code'} inputProps={{ maxLength: 6 }} InputProps={{
                endAdornment: <InputAdornment position='start'>
                {
                  value === '2' && 
                  <LoadingButton variant='contained' color='secondary' disabled={props.user?.smsActivationToken ? true : false} loading={load2} disableElevation sx={{textTransform: 'none', mr: '-.5rem'}} onClick={sendSMSCode}>Send SMS</LoadingButton>
                }
                </InputAdornment>
              }} />
              <LoadingButton size='large' disabled={ value === '2' && !props.user?.smsActivationToken ? true : false } loading={load} fullWidth variant='contained' color='secondary' disableElevation sx={{textTransform: 'none'}} onClick={() => activateUserAccount(value === '1' ? 'email' : 'sms')}>Activate My Email</LoadingButton>
              <Typography mt={2} textAlign={'center'} color='GrayText'>Didnt receive the code? <Button disableElevation variant='text' sx={{textTransform: 'none'}} onClick={() => resendEmail(value === '1' ? 'email' : 'sms')}>RESEND CODE</Button></Typography>
            </Box>
          
                  
        </DialogContent>
      </Dialog>
      
    </Box>
  )
};

const mapStateToProps = (state) => {
  //console.log(state)
  return state
}

export default connect(mapStateToProps, {activateAccountEmail, activateAccountSMS, resendSMSCode, getUserCard, resendEmailVerification})(Overview)
