import { Avatar, Divider, Grid, Typography } from '@mui/material'
import React from 'react'


const NotificationItem = ({title, date, body}) => {
    // const classes = useStyles()


  return (
    <div>
        <Grid container style={{marginTop: '1rem'}} spacing={1}>
            <Grid item xs={2} sm={2}>
                <Avatar sx={{bgcolor: 'primary.main'}} >{title.charAt(0)}</Avatar>
            </Grid>

            <Grid item xs={10} sm={10}>
                <Typography sx={{fontSize: '.9rem'}} variant='h6' noWrap>{title}</Typography>
                <Typography variant='body2' color='textSecondary' style={{marginBottom: '.2rem'}} noWrap>{body}</Typography>
                <Typography paragraph variant='body2' color='textSecondary'>
                    -- {date}
                </Typography>
            </Grid>
        </Grid>
        <Divider />
    </div>
  )
}

export default NotificationItem