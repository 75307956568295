import { AppBar, Avatar, Badge, Box, Button, Divider, Hidden, IconButton, InputAdornment, MenuItem, Popover, TextField, Toolbar, Typography } from '@mui/material';
import { Search, Notifications, Menu, WhatsApp } from '@mui/icons-material'
import { useState, useEffect } from 'react';
import { useNavigate, Navigate, useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles'
import SideBar from './dashboard/SideBar';
import Overview from './Overview';
import Settings from './Settings';
import Profile from './Profile';
import UserNotifications from './Notifications';
import { grey } from '@mui/material/colors';
import { connect } from 'react-redux';
import Support from './Support';
import { logoutUser, getUserPayment, getUser, getUserNotifications, getInterestRates } from '../../actions/actions';
import NotificationItem from '../../components/NotificationItem';
import Payment from './Payment';
import Transactions from './Transactions';
import Documents from './Documents';
// import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import Modal from '../../components/Modal';
import Downloads from './Downloads';


const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
    root : {
      display: 'flex',
      '& .MuiBadge-colorPrimary' : {
        background: theme.backgroundSecondaryDark,
        color: '#fff'
      }
    },
    drawerIcon: {
      color: '#fff',
      background: theme.primaryColorOrange,
      padding: '.4rem',
      borderRadius: '4px',
      marginRight: '1rem'
    },
    searchInput : {
      color: grey[700],
      border: 'none'
    },
    searchIcon: {
      color: grey[400]
    },
    menu : {
      padding: '1rem',
      '& li': {
          borderRadius: '.3rem'
      },
      '& h6': {
          fontSize: '1rem'
      }
    },
      // necessary for content to be below app bar
    toolbarHeight: theme.mixins.toolbar,
})
)

const Account = (props) => {
  const { currentUser } = props
  const classes = useStyles()
  const navigate = useNavigate()
  const { getUserPayment, getUser, getUserNotifications, getInterestRates } = props
  const pathname = useLocation().pathname
  //const id = pathname.split('/')[4]
  const [openState, setOpenState] = useState(false)
  const [filter, setFilter] = useState()
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if(currentUser){
      getUserPayment()
      getUser()
      getUserNotifications()
      getInterestRates()
    }
    // eslint-disable-next-line
  },[ getUserPayment, getUser, getUserNotifications, getInterestRates])

  const openDrawerHandle = () => {
    setOpenState(!openState)
  }

  const handleClose = () => {
    setAnchorEl(null);
  }
  const onhandleClick = (e, val) => {
      setAnchorEl(e.currentTarget);
      setFilter(val)

  }

  const renderPage = () => {
    if(props.currentUser && !props.currentUser.verificationCode ){
      return (
          <Box sx={{
              display: 'flex',
              '& .MuiBadge-colorPrimary' : {
                background: theme => theme.backgroundSecondaryDark,
                color: '#fff'
              }
            }}
          >

            {/* SIDEBAR DRAWER */}
              <Hidden smDown>
                  <SideBar variant='permanent' open={true} close={openDrawerHandle} user={props.currentUser} />
              </Hidden>

              <Hidden smUp>
                  <SideBar variant='temporary' open={openState} close={openDrawerHandle} user={props.currentUser} />
              </Hidden>

            {/* MAIN CONTENT */}
              <Box sx={{
                      position: 'relative',
                      flexGrow: 1,
                      background: theme => theme.contentBackground,
                      padding: '2rem',
                      borderRadius: '10px',
                      height: '100vh',
                      width: {xs: '100%', sm: '100%', md: `calc(100% - ${drawerWidth}px)`, lg: `calc(100% - ${drawerWidth}px)`} 
                  }}
              >
                  <AppBar elevation={0} position='fixed' sx={{width: {xs: '100%', sm: '100%', md: `calc(100% - ${drawerWidth}px)`, lg: `calc(100% - ${drawerWidth}px)`} }}>
                    <Toolbar sx={{display: 'flex', justifyContent: 'space-between', bgcolor: 'light.main'}}>
                          <Hidden smUp>
                            <IconButton className={classes.drawerIcon}  onClick={openDrawerHandle}>
                              <Menu />
                            </IconButton>
                          </Hidden>

                          <Hidden mdDown>
                            <div className={classes.search}>
                              <TextField className={classes.searchInput} variant='outlined' type='search' placeholder='Search...' size='small' InputProps={{
                                startAdornment: <InputAdornment position='start'><Search className={classes.searchIcon} fontSize='small' /></InputAdornment>
                              }} />
                            </div>
                          </Hidden>

                      <Box sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginLeft: 'auto',
                          gap: '.5rem'
                        }}>
                          <Hidden xsDown>
                              <IconButton sx={{bgcolor: grey[100]}} onClick={(e)=> onhandleClick(e, 'notification')}>
                                <Badge badgeContent={props.notifications.filter(el => el.status === 'unread').length}  color='primary' variant='standard'>
                                <Notifications fontSize='medium' sx={{
                                  color: '#c2c2c2',
                                    '&:hover' :{
                                      color: theme => theme.backgroundPrimary
                                    }
                                }} />
                                </Badge>
                              </IconButton>
                              
                          </Hidden>
                          <IconButton onClick={(e)=> onhandleClick(e, 'profile')}>
                            <Avatar src={props.currentUser.photo ? props.currentUser.photo : ''} />
                          </IconButton>

                          <Popover open={open} onClose={handleClose} anchorEl={anchorEl} transformOrigin={{vertical: 'top', horizontal: 'right'}} anchorOrigin={{ vertical: 'bottom', horizontal: 'right'}}>
                                { filter === 'notification' &&
                                  <Box width={'20rem'} sx={{padding: '1.5rem'}}>
                                    <div >                          
                                    <Typography mb={1} variant='h6' sx={{fontSize: '1.1rem'}}>Notifications</Typography>
                                    </div> 
                                    <Divider />     
                                    <div >
                                    { props.notifications.length > 0 ? props.notifications.filter(el => el.status === 'unread').slice(0,4).map(item => {
                                      return (
                                          <NotificationItem key={item._id} 
                                            title={item.title} body={item.body}
                                            date={new Date(item.createdAt).toDateString()}  
                                          />
                                      )
                                    })
                                    : 
                                      <Typography color='textSecondary'>You have no unread notifications</Typography>
                                    } 
                                      </div>
                                    
                                      <div >                          
                                       <Button size='medium' onClick={() => { navigate('/u/account/notifications'); handleClose() }} sx={{textTransform: 'none', fontSize: '.8rem', marginTop: '1rem'}}>View all</Button>
                                      </div>
                                </Box>
                                }

                                { filter === 'profile' &&

                                <Box width={'12rem'}>
                                    <div className={classes.menu}>                          
                                      <Typography variant='h6'>{props.currentUser.name.firstname}</Typography>
                                      <Typography variant='body2' color='textSecondary' noWrap>{props.currentUser.email}</Typography>
                                    </div> 
                                    <Divider />     
                                    <div className={classes.menu}>
                                        <MenuItem dense onClick={()=>window.location.assign('/')}>Homepage</MenuItem>
                                        <MenuItem dense onClick={()=>window.location.assign('/u/account/profile')}>Profile</MenuItem>
                                        <MenuItem dense onClick={()=>window.location.assign('/u/account/payment')}>Request Loan</MenuItem>
                                        
                                    </div>
                                    <Divider /> 
                                    
                                    <div className={classes.menu}>                          
                                        <MenuItem dense onClick={()=> props.logoutUser()}>Logout</MenuItem>
                                    </div>
                                </Box>

                                }
                            </Popover>
                          
                      </Box>
                    </Toolbar>
                  </AppBar>
                  <Toolbar />

                  { pathname === '/u/account/dashboard' ? <Overview user={props.currentUser} /> : null}
                  { pathname === '/u/account/settings' ? <Settings user={props.currentUser}  /> : null }
                  { pathname === '/u/account/transactions' ? <Transactions user={props.currentUser}  /> : null }
                  { pathname === '/u/account/payment' ? <Payment user={props.currentUser} payment={props.payment} card={props.card} /> : null }
                  { pathname === '/u/account/documents' ? <Documents user={props.currentUser} /> : null }
                  { pathname === '/u/account/profile' ? <Profile user={props.currentUser} /> : null }
                  { pathname === '/u/account/notifications' ? <UserNotifications /> : null }
                  { pathname === '/u/account/downloads' ? <Downloads user={props.currentUser} /> : null }
                  { pathname === '/u/account/support' ? <Support user={props.currentUser} /> : null }

              </Box>
        </Box>
      )
    }

    return <> <Navigate to={'/'} /> </>
  }


  return (
    <>
      { props.modal && <Modal status={props.modal.status} />}

      {/* <TawkMessengerReact propertyId="63fcd74131ebfa0fe7ef9f81" widgetId="1gq9qhmqb"/> */}
      {/* WhatApp Floating Btn */}
        <IconButton href='https://wa.me/233207760982' target={'_blank'} size='large' sx={{ position: 'fixed', bottom: '2.5%', right: {xs: '6%', sm: '6%', lg: '1.5%'}, zIndex: 5, bgcolor: '#5b9c00', '&:hover': { bgcolor: '#03254C'}}}>
            <WhatsApp sx={{color: '#fff', fontSize: '2.1rem'}} />
        </IconButton>
     { renderPage() }
    </>
  )
}


const mapStateToProps = (state) => {
  return state
}

export default connect(mapStateToProps, {logoutUser, getUserPayment, getUser, getUserNotifications, getInterestRates})(Account);
