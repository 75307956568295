import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { activateAccountEmail, errorModal } from '../actions/actions'
import Modal from '../components/Modal'
import { connect, useDispatch } from 'react-redux'
import { successModal } from '../actions/actions'
import { Box, Button, Container, LinearProgress, Typography } from '@mui/material'
import { HighlightOff, MarkEmailRead } from '@mui/icons-material'
import { useState } from 'react'

const Activate = (props) => {
  const { activateAccountEmail, currentUser } = props
  const path = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const token = path.search.split('=')[1]
  const [page, setPage] = useState(1)

  useEffect(()=>{
    const activateEmail = async() => {
      if(currentUser && path.search === ''){
        navigate('/')
        return
      }
      if(currentUser === false && path.search !== ''){
          navigate(`/auth/login?return=${token}`)
          return
      }
      else{
         const status = await activateAccountEmail(token)
         if(status === 'success'){
          dispatch(successModal('Email verification successful'))
          setPage(2)
        }
        if(status === 'email verified'){
            dispatch(successModal('Your email address is already verified'))
            setPage(2)

        }if(status === 'failed'){
          dispatch(errorModal('Sorry, email activation failed'))
          setPage(3)
        }
      }
    }


    activateEmail()
  },[currentUser, activateAccountEmail, path, token, dispatch, navigate])

  return (
    <>
      {/* MODAL  */}
      { props.modal && <Modal status={props.modal.status} />}

      <Container>
      {
        page === 1 &&
          <Box padding={'3rem'} textAlign='center' height='50vh' display={'flex'} flexDirection='column' justifyContent={'center'} alignItems='center'>
            <Typography variant='h5' gutterBottom>Activating your account...</Typography>
            <LinearProgress variant='indeterminate' />
          </Box>
      }
      {
        page === 2 &&
          <Box width={'50%'} mx={'auto'} padding={'3rem'} textAlign='center' height='50vh' display={'flex'} flexDirection='column' justifyContent={'center'} alignItems='center'>
            <MarkEmailRead color='primary' sx={{fontSize: '4.5rem'}} />
            <Typography mt={2} variant='h5' gutterBottom>Email Verified</Typography>
            <Typography mb={3} variant='body1' color={'GrayText'}>Congratulations!! Your email has been verified successfully. Please proceed to your dashboard to complete your onboarding process.</Typography>
            <Button disableElevation variant='contained' color='secondary' onClick={()=> window.close()}>Go to Dashboard</Button>
          </Box>
      }
      {
        page === 3 &&
          <Box width={'50%'} mx={'auto'} padding={'3rem'} textAlign='center' height='50vh' display={'flex'} flexDirection='column' justifyContent={'center'} alignItems='center'>
            <HighlightOff color='primary' sx={{fontSize: '4.5rem'}} />
            <Typography mt={2} variant='h5' gutterBottom>Activation Failed</Typography>
            <Typography mb={3} variant='body1' color={'GrayText'}>Sorry, something went wrong with your email eactivation. Please use the "Resend Activation Email" in your dashboard to retry the process.</Typography>
            <Button disableElevation variant='contained' color='secondary' onClick={()=> window.close()}>Go to Dashboard</Button>
          </Box>
      }
      </Container>

    </>
  )
}

const mapStateToProps = (state) => {
  return state
}

export default connect(mapStateToProps, {activateAccountEmail, successModal})(Activate);