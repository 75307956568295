import { Button, Grid, Typography, Box, Card, CardContent, styled} from '@mui/material';
import { Email, Phone } from '@mui/icons-material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { makeStyles } from '@mui/styles';
import React from 'react';
import PageHeader from './dashboard/PageHeader';

const useStyles = makeStyles(theme => ({
  btn : {
    marginBottom: '1rem',
    height: '4rem'
  },
  card : {
    padding: '3rem'
  },
  wrap : {
    '& h5' : {
      fontWeight: 600,
      fontSize: '2rem',
      marginBottom: '1rem'
    },
    '& h6' : {
      fontWeight: 500,
      marginTop: '.5rem',
      marginBottom: '1rem'
    }
  },
  faq : {
    '& h6' : {
      fontWeight: 400,
      fontSize : '1.1rem'
    }
  }
}))

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
      borderBottom: 0,
  },
  '&:before': {
      display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
  />
))(({ theme }) => ({
  backgroundColor:
      theme.palette.mode === 'dark'
          ? 'rgba(255, 255, 255, .05)'
          : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(4),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const Support = (props) => {
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState('panel1');
  const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };


  return (
      <>
          <PageHeader title={'Support'} link2={'Support'} user={props.user}/>
       
        <Card variant='outlined'>
          <CardContent sx={{padding: '3rem'}}>
            <Grid container spacing={7} className={classes.wrap}>
              <Grid item sm={3}>
                <Typography variant='h5' component='h6'>Do you still need help?</Typography>
                <Typography variant='body1' style={{marginBottom: '2rem'}} color='textSecondary' paragraph>Get access to support whenever you need (24/7).</Typography>

                <Button sx={{ mb: 1}} startIcon={<Email />} variant='contained' href={'mailto:support@wepaygh.com&subject=SendMail&body=Hello'} fullWidth>Email</Button>
                <Button startIcon={<Phone />} variant='contained' href={'tel:+233207760982'} fullWidth>Call Us</Button>

                <Box mt={'3rem'}>
                  <Typography variant='h5' component='h6'>Locate Us</Typography>
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3971.0405467511555!2d-0.18632648496896045!3d5.561008995968072!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdf907f70704c2f%3A0x3e242934cce0c052!2sForbes%20Microfinance%20Ltd!5e0!3m2!1sen!2sgh!4v1644420997746!5m2!1sen!2sgh" width="95%" height="350" title='Locate us' style={{border:'1px solid lightgrey', padding:'.5rem', marginTop: '.2rem'}} loading="lazy"></iframe>
                </Box>
              </Grid>

              <Grid item sm={9}>

              <Typography variant='h5' component='h6' gutterBottom>Frequently Asked Questions (FAQs)</Typography>
              <Box className={classes.faq}>
                  <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                          <Typography sx={{ fontSize: { xs: '.9rem', lg: '1.1rem' } }}>What is WEPAY?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                          <Typography >
                          WEPAY is an innovative Product of ForbesMF for advancing credit/loans to its customers electronically through a credit card system that can be used to buy daily household items and payment of utilities bills on any POS or online at the convenience of their home or office and cash back and ATM withdrawals. Taking advantage of WEPAY offers allows you to make payments with your WEPAY credit card with a fixed monthly repayment over a set number of months, with no need for a new credit/loan application.
                          </Typography>
                      </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                      <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                          <Typography >How do I use WEPAY?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                          <Typography >
                            There are two ways to set up WEPAY.<br/><br/>
                              1. Log into www.wepaygh.com. Register and sign in. <br/>
                              2. You can also call the number on the website for assistance.<br/>
                              3. Choose your preferred Monthly (1,2,3,4,5,6 months) Repayments at checkout.
                              Select WEPAY credit card as your payment method at checkout on WEPAY
                          </Typography>
                      </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                      <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                          <Typography >How do I register on WEPAY?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                          <Typography >
                          Log into www.wepaygh.com.<br/><br/>
                            1.  Register by following 3 easy steps. <br />
                            2.  Filling the registration forms with your personal and company details. <br />
                            3.  Uploading the requisite documents, i.e. GHANA CARD, Bank Statement and Pay slip and a profile picture for approval. ( you will receive an approve credit limit by mail and sms and a credit card will be issued in your name)
                              Apply for Loan (loan will be disbursed on your wepay credit card any time you request for top)
                              Enjoy the convenience of stress free shopping and payment while you sit at the comfort of your home or office.

                          </Typography>
                      </AccordionDetails>
                  </Accordion>
                  
                  <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                      <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                          <Typography >Who qualifies to register?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                          <Typography >
                            1. GOVERNMENT employees receiving monthly salary through Accountant General.<br/>
                            2.  GOVERNMENT employees receiving monthly salary through Ministries, departments and agencies with undertaking from the institution.<br/> 
                            3.  Private sector employees receiving salaries through a reputable Bank.<br/>
                            4.  Private sector employees receiving salaries with undertaking from the company. 

                          </Typography>
                      </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                      <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                          <Typography >What is the minimum/Maximum Credit/Loan on WEPAY?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                          <Typography >
                            Individual  Loan ranges from GH500 to GH20,000 using WEPAY credit card.<br/>
                          </Typography>
                      </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                      <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
                          <Typography >Is there a fee for using WEPAY?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                          <Typography >
                            <b>No,</b> there is no fee for using WEPAY; interest will be charged at the rate disclosed in your WEPAY offer. Other account fees, such as late payment fees, will still apply.
                          </Typography>
                      </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                      <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
                          <Typography >Where can I find my account information on WEPAY?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                          <Typography >
                            You can visit the www.wepaygh.com web app  to get your account information on the dashboard under ACCOUNTS.
                          </Typography>
                      </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                      <AccordionSummary aria-controls="panel8d-content" id="panel8d-header">
                          <Typography >Do I earn credit card rewards on WEPAY?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                          <Typography >
                            <b>Yes.</b> Rewards will be communicated to eligible customers via their email and sms.
                          </Typography>
                      </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                      <AccordionSummary aria-controls="panel9d-content" id="panel9d-header">
                          <Typography >Can i top up my WEPAY loan/Credit?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                          <Typography >
                            <b>Yes.</b> You can always top up your loan as long as you are with in your credit limit
                          </Typography>
                      </AccordionDetails>
                  </Accordion>
            </Box>

                
              </Grid>
            </Grid>
          </CardContent>
        </Card>

      </>
  )
};

export default Support;
